import { MenuItemFormFragment } from "../../types";
import { menuItemChoiceIsMissingAllIngredients, menuItemChoiceIsMissingAnyIngredients } from "../MenuItemAddOns/utils";
import { MenuItemState } from "./reducer";
import { newId } from "@notemeal/shared-ui";

export const menuItemIsMissingAllIngredients = (menuItem: MenuItemFormFragment): boolean => {
  return menuItem.servingAmounts.length === 0 && menuItem.choices.every(menuItemChoiceIsMissingAllIngredients);
};

export const menuItemIsMissingAnyIngredients = (menuItem: MenuItemFormFragment): boolean => {
  return menuItem.servingAmounts.length === 0 || menuItem.choices.some(menuItemChoiceIsMissingAnyIngredients);
};

export const newMenuItemState = (name: string, isOneOff: boolean): MenuItemState => ({
  menuItem: {
    id: newId(),
    __typename: "MenuItem",
    name,
    servingName: "serving(s)",
    description: null,
    choices: [],
    servingAmounts: [],
    isOneOff,
    imageUrl: null,
    suggestionCategory: null,
    defaultAvailableForOrder: true,
    defaultAllowSpecialRequests: false,
    defaultMaxAmount: null,
    isDeleted: false,
    score: null,
    foodCategory: null,
    history: { fromCompass: false },
  },
  maxAmount: null,
  availableForOrder: true,
  allowSpecialRequests: false,
  edited: false,
});
