import { Button, Dialog, DialogActions, DialogContent, Divider, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { nullifyEmptyStringValues, useLocaleContext } from "@notemeal/shared-ui";
import { serializeDate } from "@notemeal/utils-date-time";
import { useRefetchQueriesContext } from "../../../contexts/RefetchQueries";
import { RmrMethod, SexType, useCreateMetricAnthropometryEntryMutation, useEditAthleteBirthDateMutation } from "../../../types";
import {
    AnthropometryEntrySchema,
    AnthropometryEntryType,
    anthropometryEntryFormStateToMetricCreateInput,
    BaseAnthropometryEntrySchema,
    AnthropometryEntrySchemaOptions,
} from "../../AnthropometryEntry/Form/AnthropometryEntryFormSchema";
import { ImperialAnthropometryEntryForm } from "../../AnthropometryEntry/Form/ImperialAnthropometryForm";
import {
    MetricAnthropometryEntrySchema,
    MetricAnthropometryEntryType,
    metricAnthropometryEntryFormStateToCreateInput,
} from "../../AnthropometryEntry/Form/MetricAnthropometryEntryFormSchema";
import { MetricAnthropometryEntryForm } from "../../AnthropometryEntry/Form/MetricAnthropometryForm";
import { evictRecentAnthropometryEntriesForAthlete } from "../../AnthropometryEntry/cache";
import { AthleteBirthDateType, athleteBirthDateFormDefaultValues, AthleteBirthDateSchema, BulkAssignMealPlanTemplateAthleteEditForm } from "./BulkAssignMealPlanTemplateAthleteEditForm";
import { z } from "zod";
import { IntegerSchema, PercentBodyFatSchema } from "libs/validators/dist";

interface AnthropometryEntryModalProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  athleteSex: SexType;
  initialFormState: Partial<AnthropometryEntryType>;
  requireAge?: boolean;
  rmrMethod: RmrMethod | null;
}

export const BulkAssignMealPlanTemplateAthleteEditModal = ({ athleteId, open, onClose, athleteSex, initialFormState, requireAge, rmrMethod }: AnthropometryEntryModalProps) => {
  const refetchQueries = useRefetchQueriesContext();

  const birthDateForm = useForm<AthleteBirthDateType>({
    defaultValues: athleteBirthDateFormDefaultValues,
    resolver: zodResolver(AthleteBirthDateSchema),
  });

  const hasBirthDateFormErrors = birthDateForm.formState.isSubmitted && !birthDateForm.formState.isValid;

  const [editAthleteBirthDate] = useEditAthleteBirthDateMutation({});

  const { isMetricLocale } = useLocaleContext();

  const anthroFormOptions: AnthropometryEntrySchemaOptions = {};

  if(rmrMethod) {
    if (["average", "mifflin", "harrisBenedict"].includes(rmrMethod)) {
      anthroFormOptions.overrides = z.object({ height: IntegerSchema }).required();
    }
    if (["average", "cunningham"].includes(rmrMethod)) {
      anthroFormOptions.leanMassOrPercentSchemaRequired = true;
    }
  }

  const imperialForm = useForm<AnthropometryEntryType>({
    defaultValues: initialFormState,
    resolver: zodResolver(AnthropometryEntrySchema(anthroFormOptions)),
  });

  const metricForm = useForm<MetricAnthropometryEntryType>({
    defaultValues: initialFormState,
    resolver: zodResolver(MetricAnthropometryEntrySchema(anthroFormOptions)),
  });

  const hasAnthroFormErrors = isMetricLocale
    ? metricForm.formState.isSubmitted && !metricForm.formState.isValid
    : imperialForm.formState.isSubmitted && !imperialForm.formState.isValid;

  const [createMetricAnthro] = useCreateMetricAnthropometryEntryMutation({
    refetchQueries,
    update: (cache, { data }) => {
      if (data) {
        evictRecentAnthropometryEntriesForAthlete(athleteId, cache);
      }
    },
  });

  const submitImperialForm = () => {
    imperialForm.reset(nullifyEmptyStringValues(imperialForm.getValues()));
    imperialForm.handleSubmit(anthroEntry => {
      if (requireAge && !birthDateForm.formState.isValid && !birthDateForm.formState.isSubmitSuccessful) {
        return;
      }
      const type = anthroEntry.type;
      const date = anthroEntry.date;
      createMetricAnthro({
        variables: {
          input: anthropometryEntryFormStateToMetricCreateInput({ ...anthroEntry, type, date }, athleteId, athleteSex),
        },
        refetchQueries: ["AthletesForMealPlanTemplateAssignment"],
      });
      onClose();
    })();
  };

  const submitMetricForm = () => {
    metricForm.reset(nullifyEmptyStringValues(metricForm.getValues()));
    metricForm.handleSubmit(anthroEntry => {
      if (requireAge && !birthDateForm.formState.isValid && !birthDateForm.formState.isSubmitSuccessful) {
        return;
      }
      const type = anthroEntry.type;
      const date = anthroEntry.date;
      createMetricAnthro({
        variables: {
          input: metricAnthropometryEntryFormStateToCreateInput({ ...anthroEntry, type, date }, athleteId, athleteSex),
        },
        refetchQueries: ["AthletesForMealPlanTemplateAssignment"],
      });
      onClose();
    })();
  };

  const submitBirthDateForm = () => {
    birthDateForm.handleSubmit(({ birthDate }) => {
      if (isMetricLocale
        ? !metricForm.formState.isValid
        : !imperialForm.formState.isValid) return;
      if(birthDate) {
        editAthleteBirthDate({
          variables: {
            input: {
              athleteId,
              birthDate: serializeDate(birthDate),
            },
          },
        });
      }
    })();
  };

  const handleSubmit = () => {
    if (requireAge) {
      submitBirthDateForm();
    }
    isMetricLocale ? submitMetricForm() : submitImperialForm()
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={"Edit Athlete"} onClose={onClose} />
      <DialogContent>
        {requireAge && (
          <>
            <BulkAssignMealPlanTemplateAthleteEditForm form={birthDateForm} />
            <Divider sx={{height: 1, my: 1}}/>
          </>
        )}
        {isMetricLocale ? <MetricAnthropometryEntryForm form={metricForm} /> : <ImperialAnthropometryEntryForm form={imperialForm} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasBirthDateFormErrors || hasAnthroFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={handleSubmit}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};
