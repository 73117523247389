import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import FoodIntegrationIcon from "@mui/icons-material/LocalDining";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DiningIcon from "@mui/icons-material/Restaurant";
import { Box, Button, ButtonProps, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { useHasFeature } from "@notemeal/shared-ui";
import React from "react";
import { useHasIntegration } from "../../utils/integration";
import { BulkEditAction } from "./BulkEditDialog/types";

const FabDiv = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  gap: spacing(),
}));

interface BaseActionButtonProps {
  actionType: BulkEditAction;
  children: React.ReactChild | React.ReactChild[];
}

interface CalendarActionsProps {
  inEditMode: boolean;
  cancelBulkEdit: () => void;
  enableBulkEdit: () => void;
  handleCreate: () => void;
  bulkEditMealMenuIds: Set<string>;
  finishedBulkEditMealMenuIds: Set<string>;
  onOpenCompassDialog: () => void;
  onOpenCbordDialog: () => void;
  onOpenBonAppetitDialog: () => void;
  onOpenSodexoDialog: () => void;
  onOpenViewAttendanceDialog: () => void;
  setSelectedActionType: (actionType: BulkEditAction) => void;
}

export const CalendarActions = ({
  bulkEditMealMenuIds,
  finishedBulkEditMealMenuIds,
  inEditMode,
  cancelBulkEdit,
  enableBulkEdit,
  handleCreate,
  onOpenCompassDialog,
  onOpenCbordDialog,
  onOpenBonAppetitDialog,
  onOpenSodexoDialog,
  setSelectedActionType,
  onOpenViewAttendanceDialog,
}: CalendarActionsProps) => {
  const hasCompassIntegration = useHasIntegration("compass");
  const hasCbordIntegration = useHasIntegration("cbord");
  const hasBonAppetitIntegration = useHasIntegration("bonAppetit");
  const hasSodexoIntegration = useHasIntegration("sodexo");
  const isHubCheckinEnabled = useHasFeature("hubMenuCheckIn");
  const hasDiningOptionEnabled = useHasFeature("diningOptionInMenu");
  const proceedText = `${bulkEditMealMenuIds.size} Menu(s) selected`;
  const baseButtonProps: Partial<ButtonProps> = {
    sx: { mt: 2, width: "100%", pl: 1, pr: 1 },
    variant: "outlined",
  };

  const { palette } = useTheme();

  const baseDisabled = finishedBulkEditMealMenuIds.size > 0 || bulkEditMealMenuIds.size === 0;

  const nonCopyActionButton = {
    ...baseButtonProps,
    disabled: baseDisabled,
  };

  const toolTipText = finishedBulkEditMealMenuIds.size > 0 ? "This opperation can not be performed on Menus in the past" : "";

  const BaseActionButton = ({ actionType, children }: BaseActionButtonProps) => (
    <Tooltip title={toolTipText}>
      <Box sx={{ width: "100%" }}>
        <Button {...nonCopyActionButton} onClick={() => setSelectedActionType(actionType)}>
          <FabDiv>{children}</FabDiv>
        </Button>
      </Box>
    </Tooltip>
  );

  return inEditMode ? (
    <Box>
      <Typography>{proceedText}</Typography>
      <Button
        {...baseButtonProps}
        disabled={bulkEditMealMenuIds.size === 0}
        onClick={() => setSelectedActionType("copy")}>
        <FabDiv>
          <LibraryAddIcon sx={{ color: bulkEditMealMenuIds.size !== 0 ? "#208EEC" : undefined }} />
          Copy
        </FabDiv>
      </Button>
      <BaseActionButton actionType="delete">
        <DeleteIcon sx={{ color: !baseDisabled ? "error.main" : undefined }} />
        Delete
      </BaseActionButton>
      <BaseActionButton actionType="notification">
        <NotificationsIcon sx={{ color: !baseDisabled ? "warning.main" : undefined }} />
        Edit Notifications
      </BaseActionButton>
      <BaseActionButton actionType="sharing">
        <CompareArrowsIcon sx={{ color: !baseDisabled ? "#BB56DF" : undefined }} />
        Reassign Sharing
      </BaseActionButton>
      <BaseActionButton actionType="timing">
        <AccessTimeIcon sx={{ color: !baseDisabled ? "success.main" : undefined }} />
        Adjust Timing
      </BaseActionButton>
      {hasDiningOptionEnabled && (
        <BaseActionButton actionType="dining">
          <DiningIcon sx={{ color: !baseDisabled ? palette.accents.yellow[500] : undefined }} />
          Set Dining Options
        </BaseActionButton>
      )}
      <Button {...baseButtonProps} onClick={() => cancelBulkEdit()}>
        <FabDiv>
          <CloseIcon sx={{ color: "error.main" }} />
          Exit Edit Mode
        </FabDiv>
      </Button>
    </Box>
  ) : (
    <Box sx={{ boxSizing: "border-box", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <Button {...baseButtonProps} onClick={() => handleCreate()}>
        <FabDiv>
          <AddIcon />
          Create Menu
        </FabDiv>
      </Button>
      {hasCompassIntegration && (
        <Button {...baseButtonProps} onClick={onOpenCompassDialog}>
          <FabDiv>
            <FoodIntegrationIcon />
            Import Compass Menu
          </FabDiv>
        </Button>
      )}
      {hasCbordIntegration && (
        <Button {...baseButtonProps} onClick={onOpenCbordDialog}>
          <FabDiv>
            <FoodIntegrationIcon />
            Import Cbord Menu
          </FabDiv>
        </Button>
      )}
      {hasBonAppetitIntegration && (
        <Button {...baseButtonProps} onClick={onOpenBonAppetitDialog}>
          <FabDiv>
            <FoodIntegrationIcon />
            Import Bon Appetit Menu
          </FabDiv>
        </Button>
      )}
      {hasSodexoIntegration && (
        <Button {...baseButtonProps} onClick={onOpenSodexoDialog}>
          <FabDiv>
            <FoodIntegrationIcon />
            Import Sodexo Menu
          </FabDiv>
        </Button>
      )}
      <Button {...baseButtonProps} onClick={() => enableBulkEdit()}>
        <FabDiv>
          <EditIcon sx={{ color: palette.neutral.black }} />
          Edit Mode
        </FabDiv>
      </Button>
      {isHubCheckinEnabled && (
        <Button {...baseButtonProps} onClick={() => onOpenViewAttendanceDialog()}>
          <FabDiv>
            <CheckIcon sx={{ color: palette.neutral.black }} />
            Create Attendance Report
          </FabDiv>
        </Button>
      )}
    </Box>
  );
};
