import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { useState } from "react";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

interface DiningOptionsContentProps extends BaseContentProps {
  onBulkEdit: BaseBulkEditProps["onBulkEdit"];
  clientTimezone: BaseBulkEditProps["clientTimezone"];
}

const DiningOptionsContent = ({ isOpen, onBack, onCancel, onBulkEdit, mealMenuInstances, saving }: DiningOptionsContentProps) => {
  const mealMenuIds = mealMenuInstances.map(mealMenuInstance => mealMenuInstance.id);
  const diningOptions = mealMenuInstances.every(mealMenuInstance => mealMenuInstance.isDiningOptionEnabled);
  const [isDiningOptionEnabled, setEnableDiningOption] = useState<boolean>(diningOptions);

  const onChange = (value: boolean) => {
    setEnableDiningOption(value);
    trackEvent(`Nutrition | Kitchen | Menu Schedule | Bulk Edit | ${value ? "DiningOptionEnabled" : "DiningOptionNotEnabled"}`, {
      menuIds: mealMenuIds,
    });
  };

  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        await onBulkEdit({
          mealMenuIds,
          actionType: "dining",
          sharingState: null,
          notificationSentBeforeOrderDueInMinutes: null,
          timingState: null,
          isDiningOptionEnabled,
        });
        onBack();
      }}
      title={`Set Dining Options for ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            label={<Box>
              <Typography variant="body1">Enable Dining Options</Typography>
              <Typography variant="body2" color={"mediumEmphasisText"}>
                Will allow athletes to select order for dine-in or to-go
              </Typography>
            </Box>}
            control={<Checkbox checked={isDiningOptionEnabled} onChange={e => onChange(e.target.checked)} sx={{ mb: 2 }} />}
          />
        </Box>
      )}
      submitDisabled={mealMenuInstances.length === 0 || saving}
    />
  );
};

export default DiningOptionsContent;