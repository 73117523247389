import InfoIcon from "@mui/icons-material/Info";
import { Box, Checkbox, FormControlLabel, SxProps, Typography, TypographyOwnProps } from "@mui/material";
import { useHasFeature } from "@notemeal/shared-ui";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";

interface DiningOptionSelectProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  labelVariant?: TypographyOwnProps["variant"];
  sx?: SxProps;
}

const DiningOptionSelect = ({ value, onChange, labelVariant = "body1", sx }: DiningOptionSelectProps) => {
  const hasDiningOptionEnabled = useHasFeature("diningOptionInMenu");

  return hasDiningOptionEnabled ? (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        label={
          <Typography variant={labelVariant} sx={{ width: "max-content" }}>
            Enable Dining Options
          </Typography>
        }
        control={<Checkbox checked={value} onChange={e => onChange(e.target.checked)} />}
        sx={{ mr: 1, ...sx }}
      />
      <TWTooltip title="Enable Dining Options" subcopy="This will allow the athletes to select order for dine-in or to-go.">
        <InfoIcon fontSize="small" />
      </TWTooltip>
    </Box>
  ) : null;
};

export default DiningOptionSelect;
