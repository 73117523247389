import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, Button, Card, CardActionArea, CardContent, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import { MenuOrderItemWithAppearance, MenuSelectionItemWithAppearance, PickupTime, getMenuItemAmountsForSelection, getMenuItemMaxAmount, getMenuOrderItemsWithAppearance, getMenuSectionAmountsForSelection, getMenuSelectionItemServingAmounts, newId, reduceStatuses, useHasFeature } from "@notemeal/shared-ui";
import { Macros, ZERO_MACROS_INPUT } from "@notemeal/shared-utils-macro-protocol";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { TWTabGroup } from "../../componentLibrary/TWTabGroup/TWTabGroup";
import {
  MealMenuDiningStationWithItemPreviewsFragment,
  MealMenuForOrderFormFragment,
  MenuOrderFormFragment,
  MenuOrderItemStatus,
  MenuSelectionFormFragment,
  useMenuOrderSuggestionsAndRecentOrdersQuery,
  useRecordMenuOrderSuggestionDecisionMutation,
  useRecordMenuOrderSuggestionsViewMutation,
} from "../../types";
import { KDSOrderDiningOption } from "../../views/KDS/utils";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../../views/Kiosk/Order/orderStateReducer";
import MealMacrosAndNutrients from "../Macros/MealMacrosAndNutrients";
import { KioskMenuSelectionItemEdit } from "../MenuSelectionItem/KioskEdit";
import { KioskMenuSelectionItemNew } from "../MenuSelectionItem/KioskNew";
import { MenuOrderDiningStationKioskList } from "./DiningStation/KioskList";
import MenuOrderFormItems from "./Form/Items";
import { KioskMenuOrderForm } from "./Form/KioskMenuOrderForm";
import KioskMenuOrderFormTimePicker from "./Form/KioskTimePicker";
import { ORDER_FROM_MENU, RECENT_ORDERS, SUGGESTIONS } from "./MenuOrderUtils";
import { OrderStatusIcon } from "./OrderStatusIcon";
import MenuOrderQuickAddCards from "./QuickAdd/Cards";
import { useContentStyles } from "./useContentStyles";
import { useMenuState } from "./useMenuState";

const NEW: MenuOrderItemStatus = "new";

interface MenuOrderDialogContentProps {
  athleteId: string;
  athleteUserId?: string;
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  onChangeMenuOrderItems: (menuOrderItems: readonly MenuOrderItemWithAppearance[], editedItemId?: string) => void;
  targetMacros: Macros | null;
  mealMenu: MealMenuForOrderFormFragment;
  menuItemIds: string[];
  mealMenuTimezone: string;
  menuOrderingClosed: boolean;
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  pickupTime: PickupTime | null;
  onChangePickupTime: (pickupTime: PickupTime) => void;
  orderPickupTimes: PickupTime[];
  orderButtons: ReactNode;
  orderStatus?: MenuOrderItemStatus | null;
  avoidFoodGroupIds: string[];
  openTimePicker?: boolean;
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
  menuOrders?: readonly MenuOrderFormFragment[];
  viewOnly?: boolean;
  setDiningOption?: (option: KDSOrderDiningOption) => void;
  diningOption?: KDSOrderDiningOption;
}

const MenuOrderDialogContent = ({
  athleteId,
  athleteUserId,
  menuItemIds,
  menuOrderItems,
  onChangeMenuOrderItems,
  mealMenu,
  mealMenuDiningStations,
  menuOrderingClosed,
  targetMacros,
  mealMenuTimezone,
  pickupTime,
  orderPickupTimes,
  onChangePickupTime,
  orderStatus,
  orderButtons,
  avoidFoodGroupIds,
  openTimePicker,
  athleteOrderState,
  athleteOrderStateDispatch,
  menuOrders,
  viewOnly = false,
  setDiningOption,
  diningOption,
}: MenuOrderDialogContentProps) => {
  const classes = useContentStyles();
  const menuRef = useRef<HTMLDivElement>(null);

  const [menuState, setMenuState] = useMenuState({
    type: "mealMenuDiningStations",
    forOrder: true,
  });
  const currentOrderMenuItemAmounts = getMenuItemAmountsForSelection(menuOrderItems);
  const currentOrderDiningStationAmounts = getMenuSectionAmountsForSelection(menuOrderItems, mealMenuDiningStations);

  const allMenuItemAppearances = mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances);
  const canOrderItems = allMenuItemAppearances.some(mia => mia.availableForOrder);

  const { data: suggestionsAndRecentOrdersData } = useMenuOrderSuggestionsAndRecentOrdersQuery({
    variables: {
      targetMacros: targetMacros
        ? {
          cho: targetMacros.cho,
          pro: targetMacros.pro,
          fat: targetMacros.fat,
        }
        : ZERO_MACROS_INPUT,
      mealMenuId: mealMenu.id,
      limit: 3,
      avoidFoodGroupIds,
      athleteId,
      menuItemIds,
    },
  });

  const [recordView, { called }] = useRecordMenuOrderSuggestionsViewMutation();

  useEffect(() => {
    if (suggestionsAndRecentOrdersData?.menuOrderSuggestions && targetMacros && !called && athleteUserId) {
      recordView({
        variables: {
          input: {
            userId: athleteUserId,
            mealMenuId: mealMenu.id,
            restaurantMenuLinkId: null,
            restaurantMenuId: null,
            targetMacros: {
              cho: targetMacros.cho,
              pro: targetMacros.pro,
              fat: targetMacros.fat,
            },
            suggestions: suggestionsAndRecentOrdersData.menuOrderSuggestions.map(s => ({
              items: s.items.map(mia => ({
                menuItemId: mia.menuItem.id,
                amount: mia.amount,
                options: mia.options.map(oa => ({
                  menuItemChoiceOptionId: oa.menuItemChoiceOption.id,
                  amount: oa.amount,
                })),
              })),
            })),
          },
        },
      });
    }
  }, [mealMenu.id, suggestionsAndRecentOrdersData?.menuOrderSuggestions, targetMacros, athleteUserId, recordView, called]);

  const handleAddItem = (menuOrderItem: MenuSelectionItemWithAppearance, forOrder: boolean) => {
    onChangeMenuOrderItems([...menuOrderItems, { ...menuOrderItem, forOrder, status: "new", rating: null }]);
    setMenuState({
      type: "mealMenuDiningStations",
      forOrder,
    });
    menuRef.current?.scrollTo({
      top: 0,
    });
  };

  const handleEditItem = (menuOrderItem: MenuOrderItemWithAppearance) => {
    onChangeMenuOrderItems(
      menuOrderItems.map(moi => (moi.id === menuOrderItem.id ? menuOrderItem : moi)),
      menuOrderItem.id
    );
    setMenuState({
      type: "editMenuItem",
      menuOrderItem,
    });
  };

  const handleRemoveItem = (id: string) => {
    onChangeMenuOrderItems(menuOrderItems.filter(i => i.id !== id));
  };

  const handleChooseMenuOrderForQuickAdd = (menuOrder: MenuSelectionFormFragment) => {
    const selectionItems = menuOrder.items.flatMap(item => {
      const matchingAppearance = allMenuItemAppearances.find(mia => mia.menuItem.id === item.menuItem.id);
      if (matchingAppearance) {
        return [
          {
            id: newId(),
            __typename: "MenuOrderItem",
            forOrder: matchingAppearance.availableForOrder,
            status: NEW,
            menuItem: item.menuItem,
            menuItemAppearance: {
              id: matchingAppearance.id,
              maxAmount: matchingAppearance.maxAmount,
              availableForOrder: matchingAppearance.availableForOrder,
              allowSpecialRequests: matchingAppearance.allowSpecialRequests,
            },
            amount: item.amount,
            specialRequests: null,
            options: item.options,
            rating: null,
            percentConsumed: item.percentConsumed,
          },
        ];
      } else {
        return [];
      }
    });
    onChangeMenuOrderItems(selectionItems);
    setMenuState(null);
  };

  const menuOrderItemsForMacros =
    menuState?.type === "newMenuItem" && menuState.newMenuOrderItem ? [...menuOrderItems, menuState.newMenuOrderItem] : menuOrderItems;

  const hasAthleteMenuSuggestions = useHasFeature("athleteMenuSuggestions");
  const suggestions =
    (suggestionsAndRecentOrdersData && hasAthleteMenuSuggestions && suggestionsAndRecentOrdersData.menuOrderSuggestions) || [];
  const recentOrders = suggestionsAndRecentOrdersData
    ? suggestionsAndRecentOrdersData.menuOrderCursorConnectionForItemsForAthlete.edges
    : [];

  const [recordDecision] = useRecordMenuOrderSuggestionDecisionMutation();

  const handleChoose = (quickAddOrder: MenuSelectionFormFragment) => {
    if (displaySuggestions && athleteUserId && targetMacros) {
      recordDecision({
        variables: {
          input: {
            userId: athleteUserId,
            mealMenuId: mealMenu.id,
            restaurantMenuLinkId: null,
            restaurantMenuId: null,
            targetMacros: {
              cho: targetMacros.cho,
              pro: targetMacros.pro,
              fat: targetMacros.fat,
            },
            suggestion: {
              items: quickAddOrder.items.map(mia => ({
                menuItemId: mia.menuItem.id,
                amount: mia.amount,
                options: mia.options.map(oa => ({
                  menuItemChoiceOptionId: oa.menuItemChoiceOption.id,
                  amount: oa.amount,
                })),
              })),
            },
          },
        },
      });
    }
    handleChooseMenuOrderForQuickAdd(quickAddOrder);
  };

  const [activeTab, setActiveTab] = useState(ORDER_FROM_MENU);
  const tabs = [...(suggestions.length ? [SUGGESTIONS] : []), ...(recentOrders.length ? [RECENT_ORDERS] : []), ORDER_FROM_MENU];
  const [openEditConfirmationModal, setOpenEditConfirmationModal] = useState(false);
  const displaySuggestions = activeTab === SUGGESTIONS && suggestions.length > 0;
  const displayedSelections = displaySuggestions ? suggestions : recentOrders;
  const isEditingOrAddingItem = menuState?.type === "newMenuItem" || menuState?.type === "editMenuItem";

  return (
    <div className={classes.content}>
      {viewOnly ? (
        // TODO: Dedupe this with "No Available Menus" in apps/web/src/views/Kiosk/Configure/index.tsx
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <InfoOutlined
            color="action"
            style={{
              marginBottom: "16px",
              fontSize: "2rem",
            }}
          />
          <Typography variant="body2Semibold" gutterBottom>
            Order Limit Reached
          </Typography>
          <Typography>Looks like you have reached the order limit for this menu.</Typography>
          <Typography>Select another menu to place an order.</Typography>
        </div>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
          ref={menuRef}
        >
          {mealMenu.userOrderLimit && mealMenu.userOrderLimit > 1 && (
            <Typography className={classes.orderLimit}>* Available for multiple orders (Limit {mealMenu.userOrderLimit})</Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {tabs.length > 1 && (
              <TWTabGroup
                outsideSelected={activeTab}
                tabs={tabs}
                onSelected={selected => {
                  setMenuState({
                    type: "mealMenuDiningStations",
                    forOrder: true,
                  });
                  setActiveTab(selected);
                }}
              />
            )}
            {isEditingOrAddingItem && (
              <Button
                variant="text"
                onClick={() =>
                  setMenuState({
                    type: "mealMenuDiningStations",
                    forOrder: true,
                  })
                }
                startIcon={<ChevronLeft />}
              >
                Back to Menu
              </Button>
            )}
          </Box>
          {menuState === null || activeTab === SUGGESTIONS || activeTab === RECENT_ORDERS ? (
            <div className={classes.allCards}>
              <MenuOrderQuickAddCards
                tab={activeTab}
                menuSelections={displayedSelections}
                onChoose={handleChoose}
                targetMacros={targetMacros}
              />
              {canOrderItems && activeTab !== ORDER_FROM_MENU && (
                <Card raised>
                  <CardActionArea
                    onClick={() => {
                      setMenuState({
                        type: "mealMenuDiningStations",
                        forOrder: true,
                      });
                      setActiveTab(ORDER_FROM_MENU);
                    }}
                    disabled={menuOrderingClosed}
                  >
                    <CardContent>
                      <Typography gutterBottom variant="subtitle1Semibold">
                        Order from Menu
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p">
                        Build your order
                        <br />
                        Send to kitchen for preparation
                        <br />
                        Pick-up later at your convenience
                      </Typography>
                      {menuOrderingClosed && (
                        <Typography
                          variant="body2"
                          color="error"
                          component="p">
                          <br />
                          Ordering closed
                        </Typography>
                      )}
                      <ChevronRight className={classes.arrow} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            </div>
          ) : menuState.type === "mealMenuDiningStations" ? (
            <MenuOrderDiningStationKioskList
              forOrder={menuState.forOrder}
              mealMenuDiningStations={mealMenuDiningStations}
              currentOrderDiningStationAmounts={currentOrderDiningStationAmounts}
              currentOrderMenuItemAmounts={currentOrderMenuItemAmounts}
              onSelectItem={(menuItemAppearance, currentAmountForDiningStation, maxAmountForDiningStation) =>
                setMenuState({
                  type: "newMenuItem",
                  forOrder: menuState.forOrder,
                  menuItemAppearance,
                  newMenuOrderItem: null,
                  currentAmountForDiningStation,
                  maxAmountForDiningStation,
                })
              }
            />
          ) : menuState.type === "newMenuItem" ? (
            <KioskMenuSelectionItemNew
              menuSelectionItemId={menuState.newMenuOrderItem?.id ?? newId()}
              selectionType={menuState.forOrder ? "Order" : "Log"}
              onChange={newMenuOrderItem => {
                setMenuState({
                  ...menuState,
                  newMenuOrderItem: {
                    ...newMenuOrderItem,
                    status: "new",
                    forOrder: menuState.forOrder,
                    rating: null,
                  },
                });
              }}
              maxAmount={getMenuItemMaxAmount({
                currentAmountForMenuItem: currentOrderMenuItemAmounts[menuState.menuItemAppearance.menuItem.id] || 0,
                maxAmountForMenuItem: menuState.menuItemAppearance.maxAmount,
                currentAmountForMenuSection: menuState.currentAmountForDiningStation,
                maxAmountForMenuSection: menuState.maxAmountForDiningStation,
              })}
              availableForOrder={menuState.menuItemAppearance.availableForOrder}
              allowSpecialRequests={menuState.menuItemAppearance.allowSpecialRequests}
              menuItem={menuState.menuItemAppearance.menuItem}
              onDone={item => handleAddItem(item, menuState.forOrder)}
            />
          ) : menuState.type === "editMenuItem" ? (
            <KioskMenuSelectionItemEdit
              menuSelectionItem={menuState.menuOrderItem}
              currentAmountForMenuItem={currentOrderMenuItemAmounts[menuState.menuOrderItem.menuItem.id] || 0}
              menuSections={mealMenuDiningStations}
              menuSectionAmountsForSelection={currentOrderDiningStationAmounts}
              onEdit={item => {
                handleEditItem({
                  ...menuState.menuOrderItem,
                  ...item,
                });
              }}
              onDone={() => {
                setMenuState({
                  type: "mealMenuDiningStations",
                  forOrder: true,
                });
              }}
            />
          ) : null}
        </Box>
      )}
      <Divider orientation="vertical" flexItem />
      <div className={classes.order}>
        {(!!menuOrders?.length || !!menuOrderItems.length) && <Typography variant="h3">Order Details</Typography>}
        <KioskMenuOrderForm
          mealMenuName={mealMenu.name}
          className={classes.orderForm}
          menuOrderItems={menuOrderItems}
          onSelectItem={menuOrderItem => {
            setMenuState({
              type: "editMenuItem",
              menuOrderItem,
            });
            setActiveTab(ORDER_FROM_MENU);
          }}
          selectedItemId={menuState?.type === "editMenuItem" ? menuState.menuOrderItem.id : null}
          onRemoveItem={handleRemoveItem}
          pickupTime={pickupTime}
          onChangePickupTime={onChangePickupTime}
          orderPickupTimes={orderPickupTimes}
          mealMenuTimezone={mealMenuTimezone}
          menuOrderingClosed={menuOrderingClosed}
          openTimePicker={openTimePicker}
          setDiningOption={setDiningOption}
          diningOption={diningOption}
        />
        {(athleteOrderState.type !== "edit" || orderStatus === "new") && orderButtons}
        {!!menuOrders?.length && (
          <div className={classes.orderContainer}>
            {menuOrders.map(order => {
              if (athleteOrderState.type === "edit" && order.id === athleteOrderState.menuOrderId) {
                return null;
              }
              const items = getMenuOrderItemsWithAppearance(
                order.items,
                order.mealMenu.mealMenuDiningStations.flatMap(diningStation => diningStation.menuItemAppearances)
              );

              const thisOrderStatus = reduceStatuses(order.items.map(item => item.status));

              const orderStatusTextColor = thisOrderStatus === "new" ? "textPrimary" : "textSecondary";

              return (
                <div key={order.id}>
                  <Typography
                    variant="body2"
                    color={orderStatusTextColor}
                    className={classes.orderStatus}>
                    {<OrderStatusIcon orderStatus={thisOrderStatus} />}
                    <strong>
                      {mealMenu.name} (#{order.code})
                    </strong>
                  </Typography>
                  <KioskMenuOrderFormTimePicker
                    className={classes.timePicker}
                    forOrder={true}
                    pickupTime={{ type: "Time", value: order.pickupTime, remaining: null }}
                    onChangePickupTime={onChangePickupTime}
                    pickupTimes={orderPickupTimes}
                    mealMenuTimezone={mealMenuTimezone}
                    disabled={true}
                  />
                  <MenuOrderFormItems
                    selectedItemId={menuState?.type === "editMenuItem" ? menuState.menuOrderItem.id : null}
                    onSelectItem={menuOrderItem => {
                      setMenuState({
                        type: "editMenuItem",
                        menuOrderItem,
                      });
                      setActiveTab(ORDER_FROM_MENU);
                    }}
                    onRemoveItem={handleRemoveItem}
                    menuOrderItems={items}
                    disabled={true}
                  />
                  {/* TODO: should this be displayed in edit mode? */}
                  {thisOrderStatus ===
                    "new" /* && !(athleteOrderState.__typename === "edit" && athleteOrderState.menuOrderId === order.id) */ && (
                      <>
                        <Typography variant="body2" onClick={() => setOpenEditConfirmationModal(true)}>
                          Switch to edit order
                        </Typography>
                        <Dialog
                          open={openEditConfirmationModal}
                          onClose={() => setOpenEditConfirmationModal(false)}
                          maxWidth="xs">
                          <DialogTitle title="Switch to Edit Order" onClose={() => setOpenEditConfirmationModal(false)} />
                          <DialogContent>
                            <Typography>
                              Switching to edit the other order without updating won't save the edits you made. Are you sure you want to
                              proceed?
                            </Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" onClick={() => setOpenEditConfirmationModal(false)}>
                              Cancel
                            </Button>
                            <Button
                              onClick={() =>
                                athleteOrderStateDispatch({ type: "SwitchToEditOrderAction", payload: { menuOrderId: order.id } })
                              }
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    )}
                </div>
              );
            })}
          </div>
        )}
        <MealMacrosAndNutrients
          mealServingAmounts={menuOrderItemsForMacros.flatMap(getMenuSelectionItemServingAmounts)}
          targetMacros={targetMacros}
        />
      </div>
    </div>
  );
};

export default MenuOrderDialogContent;
