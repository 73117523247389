import { Box, IconButton, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import LoopIcon from '@mui/icons-material/Loop';
import NamedTagChipList from "apps/web/src/views/Tags/NamedTagChipList";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment, useRetryBulkAssignMealPlanTemplateJobAthleteMutation } from "apps/web/src/types";

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      cursor: "pointer",
    },
  })
);

interface BulkAssignMealPlanTemplateStatusModalAthleteListItemProps {
  athlete: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment;
  onClick?: (athlete: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment) => void;
}

export const BulkAssignMealPlanTemplateStatusModalAthleteListItem = ({
  athlete,
}: BulkAssignMealPlanTemplateStatusModalAthleteListItemProps) => {
  const classes = useStyles();
  const { succededAt, failedAt, jobId } = athlete.bulkAssignMealPlanTemplateJobAthleteOffsetConnection.edges[0];
  const parseStatusCopy = () => {
    if (succededAt !== null) {
      return ["Assignment succeeded", `Succeeded on ${new Date(succededAt).toLocaleString()}`, "success"];
    }
    if (failedAt !== null) {
      return [
        "Assignment failed",
        `Unable to assign the meal plan template to the athlete ${new Date(failedAt).toLocaleString()}. Please try again by clicking this icon.`,
        "failed",
      ];
    }
    return ["Assignment pending", "Check back again later", "pending"];
  };
  const [statusTitle, statusSubcopy, status] = parseStatusCopy();

  const [retryJobAthlete] = useRetryBulkAssignMealPlanTemplateJobAthleteMutation({});

  const handleRetryJobAthlete = async (athleteId: string) => {
    await retryJobAthlete({
    variables: {
      input: {
        jobId,
        athleteId,
      }
    }
  })};

  return (
    <Box sx={{ display: "flex", flex: 1, height: 38, my: 1, py: 1, px: 2, background: "#F8FAFB", alignItems: "center", pr: status === "failed" ? 1 : undefined }}>
      <Box sx={{ display: "flex", flex: 1 }}>
        <Typography sx={{ mr: 1 }}>{`${athlete.lastName}, ${athlete.firstName} `}</Typography>
        <NamedTagChipList
          namedTags={athlete.myNamedTagsOffsetConnection.edges}
          namedTagsCount={athlete.myNamedTagsOffsetConnection.pageInfo.total}
          chipClassName={classes.chip}
        />
      </Box>
      <Box>
        <TWTooltip title={statusTitle} subcopy={statusSubcopy}>
          {status === "success" ? (
            <CheckCircleIcon color="success" />
          ) : status === "failed" ? (
            <IconButton onClick={() => handleRetryJobAthlete(athlete.id)}><LoopIcon color="error" /></IconButton>
          ) : (
            <HistoryToggleOffIcon color="disabled" />
          )}
        </TWTooltip>
      </Box>
    </Box>
  );
};
