import { differenceInMinutes, format, parseISO } from "date-fns";
import { ImportMenuTimeInput, SodexoMealTime } from "../../../../types";
import { ImportMenuOptionsState } from "../Common/utils";

const getMealType = (mealNameIn: string) => {
  const mealName = mealNameIn.toLowerCase();

  return mealName === "breakfast"
    ? "breakfast"
    : mealName === "lunch"
    ? "lunch"
    : mealName === "dinner"
    ? "dinner"
    : mealName === "brunch"
    ? "breakfast"
    : "snack";
};

const getUniqueMenuTimes = (selectedMealTimes: readonly SodexoMealTime[]) => {
  const menuTimesMap = new Map<string, ImportMenuTimeInput>(
    selectedMealTimes.map(({ name, start, end }) => [
      name,
      {
        menuName: name,
        mealType: getMealType(name),
        startTime: format(parseISO(start), "hh:mm:ss"),
        durationInMinutes: differenceInMinutes(parseISO(end), parseISO(start)),
      },
    ])
  );

  return Array.from(menuTimesMap.values());
};

export const getInitImportMenuOptionsState = (selectedMealTimes: readonly SodexoMealTime[], timezone: string): ImportMenuOptionsState => ({
  shareState: {
    __typename: "Teams",
    teams: [],
    advancedSelectionState: null,
  },
  timezone,
  prepTimeInMinutes: 15,
  lastOrderTimeBeforeEndInMinutes: 0,
  notificationSentBeforeOrderDueInMinutes: 15,
  orderRateLimit: null,
  availableForOrder: true,
  allowSpecialRequests: false,
  overrideIdentity: false,
  userOrderLimit: null,
  menuTimes: getUniqueMenuTimes(selectedMealTimes),
  isHubCheckInEnabled: false,
  isOrderAndLogRestricted: false,
  theme: "",
  isDiningOptionEnabled: false,
});
