import AssignmentIcon from "@mui/icons-material/AssignmentRounded";

// full page path
export const NAV_ORG_MEAL_PLANS = "/org/meal-plans";

// local child paths
export const NAV_MEAL_PLAN_TEMPLATES = "meal-plan-templates";
export const NAV_MEAL_PLANS_EXCHANGE_SETS = "exchange-sets";
export const NAV_MEAL_PLAN_ASSIGN_TEMPLATE = "assign-template/:id";

// full child paths
export const NAV_ORG_MEAL_PLAN_TEMPLATES = `${NAV_ORG_MEAL_PLANS}/${NAV_MEAL_PLAN_TEMPLATES}`;
export const NAV_ORG_MEAL_PLANS_BULK_ASSIGNMENT_STATUS = `${NAV_ORG_MEAL_PLAN_TEMPLATES}?tab=assignment-status`;
export const NAV_ORG_MEAL_PLANS_EXCHANGE_SETS = `${NAV_ORG_MEAL_PLANS}/${NAV_MEAL_PLANS_EXCHANGE_SETS}`;
export const NAV_ORG_MEAL_PLAN_ASSIGN_TEMPLATE = `${NAV_ORG_MEAL_PLANS}/${NAV_MEAL_PLAN_ASSIGN_TEMPLATE}`;

export const mealPlansLink = {
  to: NAV_ORG_MEAL_PLANS,
  name: "Meal Plans",
  icon: <AssignmentIcon />,
};

export const mealPlanTemplatesLink = {
  to: NAV_ORG_MEAL_PLAN_TEMPLATES,
  name: "Meal Plan Templates",
};

export const mealPlanAssignTemplateLink = {
  to: NAV_ORG_MEAL_PLAN_ASSIGN_TEMPLATE,
  name: "Assign Meal Plan Template",
};

export const getMealPlanAssignTemplate = (templateId: string) => `${NAV_ORG_MEAL_PLAN_ASSIGN_TEMPLATE.replace(":id", templateId)}`;
