import { differenceInMinutes } from "date-fns";
import { DateTime } from "luxon";
import { BonAppetitMenusPreviewFragment, ImportMenuTimeInput } from "../../../../types";
import { ImportMenuOptionsState } from "../Common/utils";

// TODO - copied this from apps/server/src/app/utils/timezone.ts, should move to shared?
interface dateAndTimeToJSDateInTimezoneArgs {
  date: string;
  time: string;
  timezone: string;
}

const dateAndTimeToJSDateInTimezone = ({ date, time, timezone }: dateAndTimeToJSDateInTimezoneArgs): Date => {
  return DateTime.fromISO(`${date}T${time}Z`)
    .toUTC()
    .setZone(timezone, {
      keepLocalTime: true,
    })
    .toJSDate();
};

const getDuration = (date: string, startTime: string, endTime: string, timezone: string) => {
  const start = dateAndTimeToJSDateInTimezone({ date, time: startTime, timezone });
  const end = dateAndTimeToJSDateInTimezone({ date, time: endTime, timezone });
  return Math.abs(differenceInMinutes(start, end));
};

const getDayPart = (date: string, mealId: string, menusPreview: BonAppetitMenusPreviewFragment) => {
  const day = menusPreview.days.find(day => day.date === date);
  return day?.dayParts.find(dayPart => dayPart.id === mealId);
};

const getUniqueMenuTimes = (selectedMenuIds: readonly string[], menusPreview: BonAppetitMenusPreviewFragment, timezone: string) => {
  const menuTimesMap = new Map<string, ImportMenuTimeInput>();
  selectedMenuIds.forEach(menuId => {
    const [date, mealId] = menuId.split(" ");
    const dayPart = getDayPart(date, mealId, menusPreview);
    if (dayPart) {
      const { label: menuName, startTime: bonAppetitStartTime, endTime } = dayPart;
      const startTime = `${bonAppetitStartTime}:00`;
      const durationInMinutes = getDuration(date, startTime, endTime, timezone);
      const mealType =
        menuName === "Breakfast"
          ? "breakfast"
          : menuName === "Lunch"
          ? "lunch"
          : menuName === "Dinner"
          ? "dinner"
          : menuName === "Brunch"
          ? "breakfast"
          : "snack";

      menuTimesMap.set(menuName, { menuName, mealType, startTime, durationInMinutes });
    }
  });

  return Array.from(menuTimesMap.values());
};

export const getInitImportMenuOptionsState = (
  selectedMenuIds: readonly string[],
  menusPreview: BonAppetitMenusPreviewFragment,
  timezone: string
): ImportMenuOptionsState => {
  return {
    shareState: {
      __typename: "Teams",
      teams: [],
      advancedSelectionState: null,
    },
    timezone,
    prepTimeInMinutes: 15,
    lastOrderTimeBeforeEndInMinutes: 0,
    notificationSentBeforeOrderDueInMinutes: 15,
    orderRateLimit: null,
    availableForOrder: true,
    allowSpecialRequests: false,
    overrideIdentity: false,
    userOrderLimit: null,
    menuTimes: getUniqueMenuTimes(selectedMenuIds, menusPreview, timezone),
    isHubCheckInEnabled: false,
    isOrderAndLogRestricted: false, // TODO: do we need to show this in the import flow?
    theme: "",
    isDiningOptionEnabled: false,
  };
};
