import { List, ListItem, SxProps, Typography } from "@mui/material";
import { AthleteForMealPlanTemplateAssignmentFragment } from "apps/web/src/types";
import React, { useCallback } from "react";
import InfiniteScrollContainer from "../../universal/InfiniteScroll/InfiniteScrollContainer";
import { UseInfiniteCursorConnectionScrollResults } from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { BulkAssignMealPlanTemplateAthleteListItem } from "./BulkAssignMealPlanTemplateAthleteListItem";

const listItemStyle = { width: `calc(100% - ${1})` };

interface AthleteInfiniteScrollProps {
  onClickItem: (athlete: AthleteForMealPlanTemplateAssignmentFragment) => void;
  onClickNoAnthropometryItem: (athlete: AthleteForMealPlanTemplateAssignmentFragment) => void;
  selectedAthleteIds: string[];
  sx?: SxProps;
  searchResults: UseInfiniteCursorConnectionScrollResults<
    "athletesForMealPlanTemplateAssignmentCursorConnection",
    AthleteForMealPlanTemplateAssignmentFragment
  >;
}

export const BulkAssignMealPlanTemplateAthleteInfiniteScroll = ({
  onClickItem,
  onClickNoAnthropometryItem,
  selectedAthleteIds,
  sx,
  searchResults,
}: AthleteInfiniteScrollProps) => {
  const renderItem = useCallback(
    (athlete: AthleteForMealPlanTemplateAssignmentFragment, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => {
      return (
        <div ref={forwardRef} key={athlete.id}>
          <BulkAssignMealPlanTemplateAthleteListItem
            athlete={athlete}
            onClick={onClickItem}
            onClickNoAnthropometryItem={onClickNoAnthropometryItem}
            sx={listItemStyle} />
        </div>
      );
    },
    [onClickItem]
  );

  const filterEdges = useCallback(
    (athlete: AthleteForMealPlanTemplateAssignmentFragment) => !selectedAthleteIds.includes(athlete.id),
    [selectedAthleteIds]
  );

  return (
    <List disablePadding sx={sx}>
      <InfiniteScrollContainer
        noItemsComponent={
          <ListItem sx={listItemStyle}>
            <Typography>No Athletes Found</Typography>
          </ListItem>
        }
        renderItem={renderItem}
        infiniteScrollResults={searchResults}
        loadingOffset={10}
        filterEdges={filterEdges}
      />
    </List>
  );
};
