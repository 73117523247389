import { MenuOrderItemWithAppearance, TimelineMealModalIntent, getMenuOrderItemsWithAppearance, isMealMenuClosed } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { MenuOrderDialogMenuState, useMenuState } from "apps/web/src/components/MenuOrder/useMenuState";
import { MenuItemAppearancePreviewFragment, MenuOrderItemFormFragment, MenuOrderTimelineFragment } from "apps/web/src/types";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { TimelineMealForModal } from "../utils";
import { COMMENT_TAB, LOG_TAB, LogType, ORDER_CLOSED_TAB, ORDER_TAB, TimelineMealTab, getInitModalTab } from "./utils";

type TimelineMealCart_Generic_Existing<ItemType> = {
  orderStatus: "Existing";
  order: MenuOrderTimelineFragment;
  editedItems: readonly ItemType[];
  addedItems: readonly ItemType[];
  removedItemIds: readonly string[];
};

type TimelineMealCart_Generic_New<ItemType> = {
  orderStatus: "New";
  items: readonly ItemType[];
  order: null;
};

export interface TimelineMealCart_MenuOrder_New extends TimelineMealCart_Generic_New<MenuOrderItemWithAppearance> {
  type: "menuOrder";
  mealMenuId: string;
}

const cartIsNewMenuOrderCart = (cart: TimelineMealCart | null): cart is TimelineMealCart_MenuOrder_New => {
  return cart?.type === "menuOrder" && cart.orderStatus === "New";
};

const matchesMenuOrderCart_New = (mealMenuId: string, _cart: TimelineMealCart | null): _cart is TimelineMealCart_MenuOrder_New => {
  return cartIsNewMenuOrderCart(_cart) && _cart.mealMenuId === mealMenuId;
};

const cartIsExistingMenuOrderCart = (cart: TimelineMealCart | null): cart is TimelineMealCart_MenuOrder_Existing => {
  return cart?.type === "menuOrder" && cart.orderStatus === "Existing";
};

export const matchesMenuOrderCart_Existing = (
  mealMenuId: string,
  orderId: string,
  _cart: TimelineMealCart | null
): _cart is TimelineMealCart_MenuOrder_Existing => {
  return cartIsExistingMenuOrderCart(_cart) && _cart.mealMenuId === mealMenuId && _cart.order.id === orderId;
};

export interface TimelineMealCart_MenuOrder_Existing extends TimelineMealCart_Generic_Existing<MenuOrderItemWithAppearance> {
  type: "menuOrder";
  mealMenuId: string;
}
export type TimelineMealCart_MenuOrder = TimelineMealCart_MenuOrder_New | TimelineMealCart_MenuOrder_Existing;
export type TimelineMealCart = TimelineMealCart_MenuOrder;

interface MenuOrderArgs {
  mealMenuId: string;
  item: MenuOrderItemWithAppearance;
  order: MenuOrderTimelineFragment | null;
}

type MenuOrderArgs_New = Omit<MenuOrderArgs, "order">;
type MenuOrderArgs_Existing = Omit<MenuOrderArgs, "order"> & {
  order: MenuOrderTimelineFragment;
};

type RemoveMenuOrderItemArgs_New = Omit<MenuOrderArgs_New, "item"> & {
  itemId: string;
};

type RemoveMenuOrderItemArgs_Existing = Omit<MenuOrderArgs_Existing, "item"> & {
  itemId: string;
};

export type RemoveMenuOrderItemArgs = Omit<MenuOrderArgs, "item"> & {
  itemId: string;
};

const removeMenuOrderItem = (itemId: string, existingList: readonly MenuOrderItemWithAppearance[]): MenuOrderItemWithAppearance[] => {
  return existingList.filter(i => i.id !== itemId);
};

const replaceMenuOrderItem = (
  item: MenuOrderItemWithAppearance,
  existingList: readonly MenuOrderItemWithAppearance[]
): MenuOrderItemWithAppearance[] => {
  return existingList.map(i => (i.id === item.id ? item : i));
};

interface ITimelineMealModalContext {
  cart: TimelineMealCart | null;
  orderPreferencesChanged: boolean;
  setOrderPreferencesChanged: (isChanged: boolean) => void;
  currentTab: TimelineMealTab;
  setCurrentTab: (tab: TimelineMealTab) => void;
  logType: LogType | null;
  setLogType: Dispatch<SetStateAction<LogType | null>>;
  modalTabs: readonly TimelineMealTab[];
  logMenuState: MenuOrderDialogMenuState | null;
  setLogMenuState: Dispatch<SetStateAction<MenuOrderDialogMenuState | null>>;
  handleSetLogMenuState: (state: MenuOrderDialogMenuState | null) => void;
  menuState: MenuOrderDialogMenuState | null;
  setMenuState: Dispatch<SetStateAction<MenuOrderDialogMenuState | null>>;
  handleClickLogFromMenu: () => void;
  cartFns: {
    onAddMenuOrderItem: (args: MenuOrderArgs) => void;
    onRemoveMenuOrderItem: (args: RemoveMenuOrderItemArgs) => void;
    onEditMenuOrderItem: (args: MenuOrderArgs) => void;
    onAddMenuOrderItemsToEmptyCart: (mealMenuId: string, items: readonly MenuOrderItemWithAppearance[]) => void;
    changeCart: (mealMenuId: string, order: MenuOrderTimelineFragment | null) => void;
    cartHasChanges: () => boolean;
    getAllItemsWithAppearance: (allMenuItemAppearances: MenuItemAppearancePreviewFragment[]) => readonly MenuOrderItemWithAppearance[];
    getAllItems: () => readonly MenuOrderItemFormFragment[];
  }
}

const TimelineMealModalContext = createContext<ITimelineMealModalContext>({
  cart: null,
  cartFns: {
    onAddMenuOrderItem: () => { },
    onEditMenuOrderItem: () => { },
    onRemoveMenuOrderItem: () => { },
    onAddMenuOrderItemsToEmptyCart: () => { },
    cartHasChanges: () => false,
    changeCart: () => { },
    getAllItemsWithAppearance: () => [],
    getAllItems: () => [],
  },
  orderPreferencesChanged: false,
  setOrderPreferencesChanged: () => { },
  currentTab: ORDER_TAB,
  setCurrentTab: () => { },
  logType: null,
  setLogType: () => { },
  modalTabs: [],
  logMenuState: null,
  setLogMenuState: () => { },
  handleSetLogMenuState: () => { },
  menuState: null,
  setMenuState: () => { },
  handleClickLogFromMenu: () => { }
});

interface TimelineMealModalContextProviderProps {
  children: ReactNode;
  timelineMeal: TimelineMealForModal;
  intent: TimelineMealModalIntent | null;
}

// TODO: This can be simplified if we dont allow the changing of the cart by basic operations and only from the setEditCart and the createEmptyCart funcs
export const TimelineMealModalContextProvider = ({ children, timelineMeal, intent }: TimelineMealModalContextProviderProps) => {
  const hasMealMenuForOrder = timelineMeal.mealMenus.some(mm => !mm.isLogOnly);
  const firstOrderableMealMenu = timelineMeal.mealMenus.find(mm => !isMealMenuClosed(mm) && !mm.isLogOnly) ?? null;
  const hasOrderableMealMenu = firstOrderableMealMenu !== null;

  const [cart, setCart] = useState<TimelineMealCart | null>(() => {
    if (!firstOrderableMealMenu) {
      return null;
    }
    const maybeMenuOrder = timelineMeal.menuOrders.find(o => o.mealMenu.id === firstOrderableMealMenu.id) ?? null;
    if (maybeMenuOrder) {
      return {
        type: "menuOrder",
        orderStatus: "Existing",
        order: maybeMenuOrder,
        mealMenuId: firstOrderableMealMenu.id,
        addedItems: [],
        editedItems: [],
        removedItemIds: [],
      };
    }
    return {
      type: "menuOrder",
      orderStatus: "New",
      mealMenuId: firstOrderableMealMenu.id,
      items: [],
      order: null,
    };
  });
  const [pendingCart, setPendingCart] = useState<TimelineMealCart | null>(null);

  const [orderPreferencesChanged, setOrderPreferencesChanged] = useState(false);

  const [currentTab, _setCurrentTab] = useState<TimelineMealTab>(getInitModalTab({ hasOrderableMealMenu, intent }));

  const [logType, setLogType] = useState<LogType | null>(firstOrderableMealMenu ? null : "food");
  const modalTabs: readonly TimelineMealTab[] = firstOrderableMealMenu
    ? [ORDER_TAB, LOG_TAB, COMMENT_TAB]
    : hasMealMenuForOrder
      ? [ORDER_CLOSED_TAB, LOG_TAB, COMMENT_TAB]
      : [LOG_TAB, COMMENT_TAB];
  const setCurrentTab = (nextTab: TimelineMealTab) => {
    if (modalTabs.includes(nextTab)) {
      setLogMenuState({ type: "mealMenuDiningStations", forOrder: false });
      setMenuState({ type: "mealMenuDiningStations", forOrder: true });
      _setCurrentTab(nextTab);
    }
  };
  const [logMenuState, setLogMenuState] = useMenuState({ type: "mealMenuDiningStations", forOrder: false });
  const handleSetLogMenuState = (state: MenuOrderDialogMenuState | null) => {
    setCurrentTab(LOG_TAB);
    setLogMenuState(state);
    setLogType("menu");
  };

  const handleClickLogFromMenu = () => {
    setCurrentTab(LOG_TAB);
    setLogType("menu");
    setLogMenuState({ type: "mealMenuDiningStations", forOrder: false });
  };

  const editOrderFlow = timelineMeal.menuOrders.length > 0;

  const [menuState, setMenuState] = useMenuState(
    cart && editOrderFlow
      ? {
        type: "mealMenuDiningStations",
        forOrder: true,
      }
      : undefined
  );

  const cartHasChanges = () => {
    if (pendingCart) {
      return true;
    }
    if (cart === null) {
      return false;
    }
    if (cart.orderStatus === "New") {
      return cart.items.length > 0;
    }
    return cart.editedItems.length > 0 || cart.removedItemIds.length > 0 || cart.addedItems.length > 0;
  };

  // Do we want to allow next cart to be null?
  const __handleChangedCart = (nextCart: TimelineMealCart) => {
    if (cartHasChanges()) {
      setPendingCart(nextCart);
    } else {
      setCart(nextCart);
      setMenuState({ type: "mealMenuDiningStations", forOrder: true });
      setLogMenuState({ type: "mealMenuDiningStations", forOrder: false });
    }
  };

  const __onAddMenuOrderItem_New = ({ mealMenuId, item }: MenuOrderArgs_New) => {
    if (matchesMenuOrderCart_New(mealMenuId, cart)) {
      setCart({
        ...cart,
        items: [...cart.items, item],
      });
      return;
    }
    const nextCart: TimelineMealCart_MenuOrder_New = {
      type: "menuOrder",
      orderStatus: "New",
      mealMenuId,
      items: [item],
      order: null,
    };
    __handleChangedCart(nextCart);
  };

  const __onAddMenuOrderItem_Existing = ({ mealMenuId, order, item }: MenuOrderArgs_Existing) => {
    if (matchesMenuOrderCart_Existing(mealMenuId, order.id, cart)) {
      setCart({
        ...cart,
        addedItems: [...cart.addedItems, item],
      });
      return;
    }
    const nextCart: TimelineMealCart_MenuOrder_Existing = {
      type: "menuOrder",
      orderStatus: "Existing",
      order,
      mealMenuId,
      addedItems: [item],
      editedItems: [],
      removedItemIds: [],
    };
    __handleChangedCart(nextCart);
  };

  const onAddMenuOrderItem = ({ order, ...restArgs }: MenuOrderArgs) => {
    if (order === null) {
      __onAddMenuOrderItem_New(restArgs);
    } else {
      __onAddMenuOrderItem_Existing({ order, ...restArgs });
    }
  };

  const __onRemoveMenuOrderItem_New = ({ mealMenuId, itemId }: RemoveMenuOrderItemArgs_New) => {
    if (matchesMenuOrderCart_New(mealMenuId, cart)) {
      setCart({
        ...cart,
        items: removeMenuOrderItem(itemId, cart.items),
      });
      return;
    }
  };

  const __onRemoveMenuOrderItem_Existing = ({ mealMenuId, itemId, order }: RemoveMenuOrderItemArgs_Existing) => {
    const isExistingItem = order.items.map(item => item.id).includes(itemId);
    if (matchesMenuOrderCart_Existing(mealMenuId, order.id, cart)) {
      const removedItemIds = isExistingItem ? [...cart.removedItemIds, itemId] : cart.removedItemIds;
      setCart({
        ...cart,
        addedItems: removeMenuOrderItem(itemId, cart.addedItems),
        editedItems: removeMenuOrderItem(itemId, cart.editedItems),
        removedItemIds,
      });
      return;
    }
    const nextCart: TimelineMealCart_MenuOrder_Existing = {
      type: "menuOrder",
      orderStatus: "Existing",
      order,
      mealMenuId,
      addedItems: [],
      editedItems: [],
      removedItemIds: isExistingItem ? [itemId] : [],
    };
    __handleChangedCart(nextCart);
  };

  const onRemoveMenuOrderItem = ({ order, ...restArgs }: RemoveMenuOrderItemArgs) => {
    if (order === null) {
      __onRemoveMenuOrderItem_New(restArgs);
    } else {
      __onRemoveMenuOrderItem_Existing({ order, ...restArgs });
    }
  };

  const __onEditMenuOrderItem_New = ({ mealMenuId, item }: MenuOrderArgs_New) => {
    if (matchesMenuOrderCart_New(mealMenuId, cart)) {
      setCart({
        ...cart,
        items: cart.items.map(i => (i.id === item.id ? item : i)),
      });
      return;
    }
  };

  const __onEditMenuOrderItem_Existing = ({ mealMenuId, order, item }: MenuOrderArgs_Existing) => {
    if (matchesMenuOrderCart_Existing(mealMenuId, order.id, cart)) {
      const isExistingItem = order.items.map(({ id }) => id).includes(item.id);
      const editedItems = isExistingItem
        ? cart.editedItems.map(({ id }) => id).includes(item.id)
          ? replaceMenuOrderItem(item, cart.editedItems)
          : [...cart.editedItems, item]
        : cart.editedItems;
      const addedItems = isExistingItem ? cart.addedItems : replaceMenuOrderItem(item, cart.addedItems);
      setCart({
        ...cart,
        addedItems,
        editedItems,
      });
      return;
    }
    const nextCart: TimelineMealCart_MenuOrder_Existing = {
      type: "menuOrder",
      orderStatus: "Existing",
      order,
      mealMenuId,
      addedItems: [],
      editedItems: [item],
      removedItemIds: [],
    };
    __handleChangedCart(nextCart);
  };

  const onEditMenuOrderItem = ({ order, ...restArgs }: MenuOrderArgs) => {
    if (order === null) {
      __onEditMenuOrderItem_New(restArgs);
    } else {
      __onEditMenuOrderItem_Existing({ order, ...restArgs });
    }
  };

  const onAddMenuOrderItemsToEmptyCart = (mealMenuId: string, items: readonly MenuOrderItemWithAppearance[]) => {
    if (cart === null || !cartHasChanges()) {
      setCart({
        type: "menuOrder",
        orderStatus: "New",
        mealMenuId,
        items,
        order: null,
      });
    }
  };

  const __createNewOrderCart = (mealMenuId: string) => {
    __handleChangedCart({
      type: "menuOrder",
      orderStatus: "New",
      mealMenuId,
      items: [],
      order: null,
    });
  };

  const __setEditOrder = (mealMenuId: string, order: MenuOrderTimelineFragment) => {
    const nextCart: TimelineMealCart_MenuOrder_Existing = {
      type: "menuOrder",
      orderStatus: "Existing",
      order,
      mealMenuId,
      addedItems: [],
      editedItems: [],
      removedItemIds: [],
    };
    __handleChangedCart(nextCart);
  };

  const changeCart = (mealMenuId: string, order: MenuOrderTimelineFragment | null) => {
    if (order === null) {
      __createNewOrderCart(mealMenuId);
    } else {
      __setEditOrder(mealMenuId, order);
    }
  };

  const getAllItemsWithAppearance = (
    allMenuItemAppearances: MenuItemAppearancePreviewFragment[]
  ): readonly MenuOrderItemWithAppearance[] => {
    if (cart === null) {
      return [];
    }
    if (cart.orderStatus === "New") {
      return cart.items;
    }
    const allOldMenuItems = getMenuOrderItemsWithAppearance(cart.order.items, allMenuItemAppearances);
    const filteredEditedItems = allOldMenuItems.flatMap(i => {
      if (cart.removedItemIds.includes(i.id)) {
        return [];
      }
      const maybeEditedItem = cart.editedItems.find(editedItem => editedItem.id === i.id);
      return maybeEditedItem ? maybeEditedItem : i;
    });
    return [...filteredEditedItems, ...cart.addedItems];
  };

  const getAllItems = (): readonly MenuOrderItemFormFragment[] => {
    if (cart === null) {
      return [];
    }
    if (cart.orderStatus === "New") {
      return cart.items;
    }
    const filteredEditedItems = cart.order.items.flatMap(i => {
      if (cart.removedItemIds.includes(i.id)) {
        return [];
      }
      const maybeEditedItem = cart.editedItems.find(editedItem => editedItem.id === i.id);
      return maybeEditedItem ? maybeEditedItem : i;
    });
    return [...filteredEditedItems, ...cart.addedItems];
  };

  return (
    <TimelineMealModalContext.Provider
      value={{
        cart,
        cartFns: {
          onAddMenuOrderItem,
          onRemoveMenuOrderItem,
          onEditMenuOrderItem,
          onAddMenuOrderItemsToEmptyCart,
          cartHasChanges,
          changeCart,
          getAllItemsWithAppearance,
          getAllItems,
        },
        orderPreferencesChanged,
        setOrderPreferencesChanged,
        currentTab,
        setCurrentTab,
        logType,
        setLogType,
        modalTabs,
        logMenuState,
        setLogMenuState,
        handleSetLogMenuState,
        menuState,
        setMenuState,
        handleClickLogFromMenu,
      }}
    >
      {children}
      {pendingCart && (
        <ConfirmationDialog
          open={!!pendingCart}
          title="Switch Orders"
          message="Your order contains updates. Discard your changes and continue?"
          onCancel={() => setPendingCart(null)}
          onConfirm={() => {
            setCart(pendingCart);
            setPendingCart(null);
            setMenuState({ type: "mealMenuDiningStations", forOrder: true });
            setLogMenuState({ type: "mealMenuDiningStations", forOrder: false });
          }}
          confirmLabel="Discard"
          variant="containedDestructive"
        />
      )}
    </TimelineMealModalContext.Provider>
  );
};

export const useTimelineMealModalContext = () => useContext(TimelineMealModalContext);
