import { useApolloClient } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { NutritionColorIcon, useHasFeature, useLocaleContext } from "@notemeal/shared-ui";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { BulkAssignMealPlanTemplateStatus } from "apps/web/src/components/MealPlanTemplate/BulkAssignMealPlanTemplate/BulkAssignMealPlanTemplateStatus";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useOffsetPagination } from "apps/web/src/utils/pagination";
import CreateOrgMealPlanTemplate from "apps/web/src/views/MealPlanTemplates/CreateOrgMealPlanTemplate";
import { ManagedByMultiSelect } from "apps/web/src/views/MealPlanTemplates/ManagedByMultiselect";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import {
  BulkAssignMealPlanTemplateJobCreatedByFragment,
  MealPlanTemplateOwnerPreviewFragment,
  useBulkAssignMealPlanTemplateJobOffsetConnectionLazyQuery,
  useGoalTypesQuery,
  useResourceSharingOrgGroupsQuery,
} from "../../../../types";
import { OrgGroupMealPlanTemplates } from "../../../../views/MealPlanTemplates/OrgGroupMealPlanTemplates";
import { OrgMealPlanTemplates } from "../../../../views/MealPlanTemplates/OrgMealPlanTemplates";
import { StaffMealPlanTemplates } from "../../../../views/MealPlanTemplates/StaffMealPlanTemplates";
import { CreatedByMultiselect } from "apps/web/src/views/MealPlanTemplates/CreatedByMultiselect";

export const ROWS_PER_PAGE = 10;
export const ORG = "Organization Templates";
export const ORG_GROUP = "Org Group Templates";
export const STAFF = "Templates";
export const BULK_ASSIGNMENTS_STATUS = "Assignment Status";

const getTabFromSearchParams = (searchParams: URLSearchParams) => {
  const tab = searchParams.get("tab");

  // allows navigation from the assignment flow page back to this page with the assignment status tab selected by default
  if (tab === "assignment-status") {
    return BULK_ASSIGNMENTS_STATUS;
  }

  return ORG;
};

export const MealPlanTemplatesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMetricLocale } = useLocaleContext();
  const goalTypeResult = useGoalTypesQuery();
  const goalTypes = goalTypeResult.data?.goalTypes || [];

  const orgGroupResult = useResourceSharingOrgGroupsQuery();
  const hasOrgGroupTemplates = (orgGroupResult.data && orgGroupResult.data.resourceSharingOrgGroups.length > 0) ?? false;
  const hasMealPlanTemplateBulkAssignments = useHasFeature("mealPlanTemplateBulkAssignments");

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const tabFromSearchParams = getTabFromSearchParams(searchParams);
  const [selected, setSelected] = useState(tabFromSearchParams);

  const staffTab = {
    label: STAFF,
    icon: <NutritionColorIcon />,
  };
  const tabs: (
    | string
    | {
        label: string;
        icon: React.JSX.Element;
      }
  )[] = [ORG];

  if (hasOrgGroupTemplates) {
    tabs.push(ORG_GROUP);
  }

  if (!isMetricLocale) {
    tabs.push(staffTab);
  }

  if (hasMealPlanTemplateBulkAssignments) {
    tabs.push(BULK_ASSIGNMENTS_STATUS);
  }

  const changeTab = (tab: string) => handleSelected(tab);
  const pagination = useOffsetPagination(ROWS_PER_PAGE);
  const { queryText, onChangeQueryText, onChangePage } = pagination;

  const [createdByQueryText, setCreatedByQueryText] = useState("");

  const [selectedUsers, setSelectedUsers] = useState<MealPlanTemplateOwnerPreviewFragment[]>([]);
  const [selectedCreatedByUsers, setSelectedCreatedByUsers] = useState<BulkAssignMealPlanTemplateJobCreatedByFragment[]>([]);

  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();
  const onCompleteAction = (action: string, name: string, resetPaging: boolean, mealPlanTemplateId?: string) => {
    if (resetPaging) {
      // Evict both org and orgGroup queries, since meal plan template can be transferred between org and org group
      apolloClient.cache.evict({ fieldName: "mealPlanTemplateOffsetConnection", broadcast: true });
      apolloClient.cache.evict({ fieldName: "orgGroupMealPlanTemplateOffsetConnection", broadcast: true });
      // Also evict org group owner query, since the set of owners may have changed
      apolloClient.cache.evict({ fieldName: "orgGroupMealPlanTemplateOwnerCursorConnection", broadcast: true });
    }
    setMessage("success", `Successfully ${action} Meal Plan Template ${name}`);
    setSelectedTemplateId(mealPlanTemplateId || null);
  };

  const onCopiedTemplate = (mealPlanTemplateId: string) => {
    onCompleteAction("copied", "", true);
    handleSelected(ORG);
    setSelectedTemplateId(mealPlanTemplateId);
  };

  const handleSelected = (selected: string) => {
    onChangeQueryText("");
    setCreatedByQueryText("");
    setSelected(selected);
  };

  useEffect(() => {
    if (searchParams.get("tab") === "assignment-status") {
      // fixes unresponsive click to primary nav icon to Assignment Status if user was on other tab in this page already
      if (selected !== BULK_ASSIGNMENTS_STATUS) {
        handleSelected(BULK_ASSIGNMENTS_STATUS);
      }
      setSearchParams({});
    }
  }, [searchParams]);

  const [showCreate, setShowCreate] = useState(false);

  const { offset, limit, query: debouncedSearchText } = pagination;
  const createdByIds = selectedCreatedByUsers.length === 0 ? null : selectedCreatedByUsers.map(u => u.id);
  const [loadBulkAssignMealPlanTemplateJobOffset, { loading, data, refetch: refetchBulkAssignmentStatus }] =
    useBulkAssignMealPlanTemplateJobOffsetConnectionLazyQuery({
      variables: {
        input: {
          pagination: { offset, limit },
          searchTextMealPlanName: debouncedSearchText ?? null,
          createdByIds,
          filterByStatus: null,
        },
      },
    });

  return (
    <Box sx={{ flex: 1, height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <TWTabGroup
          tabs={tabs}
          onSelected={handleSelected}
          outsideSelected={selected} />
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", alignItems: "flex-end" }}>
          {selected === ORG_GROUP && (
            <ManagedByMultiSelect
              searchText={queryText}
              selectedUsers={selectedUsers}
              onChangeSelectedUsers={setSelectedUsers}
              sx={{ minWidth: 200 }}
            />
          )}
          {selected === BULK_ASSIGNMENTS_STATUS && (
            <CreatedByMultiselect
              searchText={createdByQueryText}
              selectedUsers={selectedCreatedByUsers}
              onChangeSelectedUsers={setSelectedCreatedByUsers}
              sx={{ minWidth: 200 }}
            />
          )}
          {selected !== ORG_GROUP && (
            <TextField
              sx={{ width: 300 }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={queryText}
              onChange={e => onChangeQueryText(e.target.value)}
            />
          )}
          {selected === ORG && <Button onClick={() => setShowCreate(true)}>Create Template</Button>}
          {selected === BULK_ASSIGNMENTS_STATUS && (
            <Button
              onClick={() => refetchBulkAssignmentStatus && refetchBulkAssignmentStatus()}
              variant="outlined"
              startIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
          )}
        </Box>
      </Box>
      {selected === ORG && (
        <OrgMealPlanTemplates
          pagination={pagination}
          onCompleteAction={onCompleteAction}
          onSelectTemplateId={setSelectedTemplateId}
          selectedTemplateId={selectedTemplateId}
          orgGroups={orgGroupResult.data?.resourceSharingOrgGroups || []}
          goalTypes={goalTypes}
        />
      )}
      {selected === ORG_GROUP && (
        <OrgGroupMealPlanTemplates
          changeTab={changeTab}
          selectedUsers={selectedUsers}
          onCompleteAction={onCompleteAction}
          onSelectEditTemplateId={setSelectedTemplateId}
          selectedEditTemplateId={selectedTemplateId}
          goalTypes={goalTypes}
          hasOrgGroupTemplates={hasOrgGroupTemplates}
        />
      )}
      {selected === STAFF && <StaffMealPlanTemplates
        pagination={pagination}
        onCopiedTemplate={onCopiedTemplate}
        goalTypes={goalTypes} />}
      {selected === BULK_ASSIGNMENTS_STATUS && (
        <BulkAssignMealPlanTemplateStatus
          loadBulkAssignMealPlanTemplateJobOffset={loadBulkAssignMealPlanTemplateJobOffset}
          pagination={pagination}
          loading={loading}
          data={data}
        />
      )}
      {showCreate && (
        <CreateOrgMealPlanTemplate
          open={showCreate}
          onCompleteAction={(...args) => {
            onCompleteAction(...args);
            setShowCreate(false);
            onChangePage(0);
          }}
          onClose={() => setShowCreate(false)}
          goalTypes={goalTypes}
        />
      )}
    </Box>
  );
};
