import { useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared-ui";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { greyscaleLight } from "apps/web/src/pages/Theme/Colors/Greyscale";
import React, { Dispatch, useReducer, useState } from "react";
import RestaurantSearchDialog from "../../../components/Restaurant/Search/Dialog";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import {
    DeliveryLocationMenuLinkFormFragment,
    RestaurantLocationPreviewFragment,
    RestaurantMenuDocument,
    RestaurantMenuQuery,
    RestaurantMenuQueryVariables,
    RestaurantPreviewFragment,
    TeamMealMenuPreviewFragment,
    useOverrideMealMenuIdentityMutation,
} from "../../../types";
import DiningStationGroupForm from "../DiningStation/GroupForm";
import MealMenuDiningStationReadonlyGroupForm from "../DiningStation/ReadonlyGroupForm";
import RestaurantMenuLinkForm from "../RestaurantMenu/Form";
import { onSaveMenuArgs } from "../hooks";
import { MenuDialogAction, menuReducer } from "../reducer";
import { MenuDialogState } from "../types";
import { menuHasIdentityWithoutOverride } from "../utils";
import MenuAvatars from "./MenuAvatars";
import { MenusDialogToolbarCardContent as ToolbarCardContent } from "./ToolbarCardContent";
import { getNewMenuDialogTooltips, getStandaloneMenuDialogTooltips } from "./utils";

interface MenusDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (args: onSaveMenuArgs) => void;
  onDelete: (state: MenuDialogState) => void;
  saving: boolean;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  initialState: MenuDialogState;
}

export const MenusDialog = ({ open, onClose, initialState, onSave, onDelete, saving, allTeams }: MenusDialogProps) => {
  const [state, dispatch] = useReducer(menuReducer, initialState);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  const tooltipItems = state.__typename === "New" ? getNewMenuDialogTooltips(state) : getStandaloneMenuDialogTooltips(state);

  const [overrideMealMenuIdentity, { loading }] = useOverrideMealMenuIdentityMutation();
  const handleOverrideMealMenuIdentity = async () => {
    if (state.identity) {
      await overrideMealMenuIdentity({
        variables: {
          input: {
            mealMenuIdentityId: state.identity.id,
          },
        },
      });
      dispatch({
        type: "MenuOverrideIdentity",
        payload: {},
      });
    }
  };

  const handleSave = () => {
    if (tooltipItems.length) {
      return;
    }

    onSave({
      state,
      initialDiningStations: initialState.mealMenuDiningStations,
      initialRestaurantMenuLinks: initialState.restaurantMenuLinks,
    });
  };

  const handleDelete = () => {
    setConfirmDeleteOpen(true);
  };

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.edited);
  }, [state.edited, setBrowserBackAndRefreshWarningEnabled]);

  const handleClose = () => {
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen>
      <DialogTitle
        sx={{
          display: "flex",
          backgroundColor: "background.paper",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 2,
          width: "100%",
          position: "fixed",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
          <IconButton
            disabled={saving}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h3">{state.__typename === "New" ? "Create Menu" : "Edit Menu"}</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, pr: 6 }}>
          {state.__typename === "New" ? null : (
            <Button
              sx={{ color: "error.main", borderColor: "error.main" }}
              variant="outlined"
              onClick={handleDelete}>
              Delete
            </Button>
          )}
          <TWItemizedTooltip title="Fix the following:" items={tooltipItems}>
            <LoadingButton
              disabled={!state.edited}
              loading={saving}
              onClick={handleSave}
              buttonText="Save" />
          </TWItemizedTooltip>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 7 }}>
        <Box sx={{ display: "flex", flexDirection: "column", mr: 4 }}>
          <Card sx={{ display: "flex", height: "100%", ml: 8, mt: 1 }} variant="outlined">
            <ToolbarCardContent
              state={state}
              dispatch={dispatch}
              onOverrideMealMenuIdentity={handleOverrideMealMenuIdentity}
              allTeams={allTeams}
              showAdvancedSelectionClear={true}
            />
          </Card>
        </Box>
        <Box sx={{ ml: 6 }}>
          <MenusContent state={state} dispatch={dispatch} />
        </Box>
      </DialogContent>
      {loading && <LoadingBackdrop open onClose={() => {}} />}
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
      {confirmDeleteOpen && (
        <ConfirmationDialog
          open={confirmDeleteOpen}
          title="Delete Menu"
          message="Are you sure that you would like to delete the menu?"
          onCancel={() => setConfirmDeleteOpen(false)}
          onConfirm={() => onDelete(state)}
          variant="containedDestructive"
        />
      )}
    </Dialog>
  );
};

interface MenusDialogContentProps {
  state: MenuDialogState;
  dispatch: Dispatch<MenuDialogAction>;
}

const MenusContent = ({ state, dispatch }: MenusDialogContentProps) => {
  const apolloClient = useApolloClient();
  const hasRestaurantMenus = useHasFeature("hasRestaurantMenus");
  const [restaurantSearchOpen, setRestaurantSearchOpen] = useState(false);
  const [restaurantQueryRunning, setRestaurantQueryRunning] = useState(false);
  const selectedRestaurantMenuLink = state.restaurantMenuLinks.find(l => l.id === state.selectedRestaurantMenuLinkId);

  const hasIdentityWithoutOverride = state.__typename === "StandaloneMealMenu" && menuHasIdentityWithoutOverride(state);
  const showRestaurantMenuAvatars = !hasIdentityWithoutOverride && hasRestaurantMenus;

  const innerContent = hasIdentityWithoutOverride ? (
    <MealMenuDiningStationReadonlyGroupForm mealMenu={state} dispatch={dispatch} />
  ) : selectedRestaurantMenuLink ? (
    <RestaurantMenuLinkForm restaurantMenuLink={selectedRestaurantMenuLink} dispatch={dispatch} />
  ) : (
    <DiningStationGroupForm
      mealMenuDiningStations={state.mealMenuDiningStations}
      dispatch={dispatch}
      orderItemCounts={state.__typename === "StandaloneMealMenu" ? state.orderItemCounts : {}}
      userOrderLimit={state.userOrderLimit}
    />
  );

  const handleAddRestaurantMenuLink = async (
    restaurant: RestaurantPreviewFragment,
    restaurantMenuId: string,
    restaurantLocation?: RestaurantLocationPreviewFragment | null,
    deliveryLocation?: DeliveryLocationMenuLinkFormFragment
  ) => {
    setRestaurantQueryRunning(true);
    setRestaurantSearchOpen(false);
    const { data } = await apolloClient.query<RestaurantMenuQuery, RestaurantMenuQueryVariables>({
      query: RestaurantMenuDocument,
      variables: {
        id: restaurantMenuId,
      },
    });
    dispatch({
      type: "MenuAddRestaurantMenuLinkAction",
      payload: {
        restaurant,
        restaurantMenu: data.restaurantMenu,
        restaurantLocation: restaurantLocation ?? null,
        deliveryLocation: deliveryLocation ?? null,
      },
    });
    setRestaurantQueryRunning(false);
    setRestaurantSearchOpen(false);
  };

  return (
    <>
      <DialogContent sx={{ position: "relative", padding: 0 }}>
        {showRestaurantMenuAvatars && (
          <MenuAvatars
            onAddRestaurant={() => setRestaurantSearchOpen(true)}
            selectedRestaurantMenuLinkId={state.selectedRestaurantMenuLinkId}
            onSelectRestaurantMenuLink={selectedRestaurantMenuLinkId =>
              dispatch({
                type: "MenuChangeSelectedRestaurantMenuLinkId",
                payload: {
                  selectedRestaurantMenuLinkId,
                },
              })
            }
            onRemoveRestaurantMenuLink={restaurantMenuLinkId =>
              dispatch({
                type: "MenuRemoveRestaurantMenuLink",
                payload: {
                  restaurantMenuLinkId,
                },
              })
            }
            restaurantMenuLinks={state.restaurantMenuLinks}
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: `16px 15%`, overflowY: "auto", height: "100%" }}>
          <Box sx={{ backgroundColor: greyscaleLight[100], height: "88px", py: 1, pl: 2, borderRadius: "4px" }}>
            <TextField
              label="Theme (optional)"
              value={state.theme}
              sx={{ width: "600px" }}
              onChange={e =>
                dispatch({
                  type: "MenuChangeThemeAction",
                  payload: { theme: e.target.value },
                })
              }
            ></TextField>
          </Box>
          {innerContent}
        </Box>
      </DialogContent>
      {restaurantSearchOpen && (
        <RestaurantSearchDialog
          open={restaurantSearchOpen}
          onClose={() => {
            setRestaurantQueryRunning(false);
            setRestaurantSearchOpen(false);
          }}
          onSelectLocation={({ restaurant, restaurantLocation, restaurantMenu }, deliveryLocation) =>
            handleAddRestaurantMenuLink(restaurant, restaurantMenu.id, restaurantLocation, deliveryLocation)
          }
          onSelectMenu={({ restaurant, restaurantMenu }) => handleAddRestaurantMenuLink(restaurant, restaurantMenu.id)}
          saving={restaurantQueryRunning}
          usedRestaurantIds={state.restaurantMenuLinks.map(rml => rml.restaurant.id)}
          deliveryLocation={state.restaurantMenuLinks.find(rml => rml.deliveryLocation !== null)?.deliveryLocation ?? undefined}
        />
      )}
    </>
  );
};
