import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button, CardContent, Dialog, DialogContent, IconButton, TextField, styled } from "@mui/material";
import { DesktopDatePicker, TimeClock } from "@mui/x-date-pickers";
import { useHasFeature } from "@notemeal/shared-ui";
import { parseDate, parseTime, safeSerializeDate } from "@notemeal/utils-date-time";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { addMinutes, differenceInMinutes } from "date-fns";
import { useState } from "react";
import MealMenuIdentityChip from "../../../components/MealMenuIdentity/Chip";
import { TeamMealMenuPreviewFragment } from "../../../types";
import AdvancedSelectionDialog from "../../Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import ShareWithInfo from "../../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import { buildInitialCreateAdvancedSelectionStateFromTeams } from "../../Tags/reducers/advancedSelectionReducers";
import DiningOptionSelect from "../SelectComponents/DiningOptionSelect";
import { EndTimeSelect } from "../SelectComponents/EndTimeSelect";
import { HubCheckIn } from "../SelectComponents/HubCheckIn";
import LastOrderDaySelect from "../SelectComponents/LastOrderDaySelect";
import MealTypeSelect from "../SelectComponents/MealTypeSelect";
import PrepTimeSelect from "../SelectComponents/PrepTimeSelect";
import StartDatesMultiSelect from "../SelectComponents/StartDatesMultiSelect";
import StartTimeSelect from "../SelectComponents/StartTimeSelect";
import TeamMultiSelect from "../SelectComponents/TeamMultiSelect";
import TimezoneSelect from "../SelectComponents/TimezoneSelect";
import { MenuDialogAction } from "../reducer";
import { MenuDialogState } from "../types";
import { getNoItemsAvailableForOrder } from "../utils";
import NotificationSettingsButton from "./NotificationSettingsButton";
import OrderRateLimitButton from "./OrderRateLimit/Button";
import { RestrictAccessToggle } from "./RestrictAccessToggle";
import { endsOnDifferentDay, isLastOrderInvalid } from "./utils";

const SubHeader = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-end",
  marginLeft: 4,
  marginBottom: spacing(),
}));

const Row = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
}));

interface MenusDialogToolbarProps {
  state: MenuDialogState;
  dispatch: (action: MenuDialogAction) => void;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  onOverrideMealMenuIdentity: () => void;
  showAdvancedSelectionClear: boolean;
}

export const MenusDialogToolbarCardContent = ({
  dispatch,
  allTeams,
  state,
  onOverrideMealMenuIdentity,
  showAdvancedSelectionClear,
}: MenusDialogToolbarProps) => {
  const isHubCheckInEnabled = useHasFeature("hubMenuCheckIn");
  const isDietitianOnlyMenusEnabled = useHasFeature("dietitianOnlyMenus");
  const [advancedSelectOpen, setAdvancedSelectOpen] = useState(false);
  const [endTimeClockOpen, setEndTimeClockOpen] = useState(false);
  const lastOrderStatus = isLastOrderInvalid(state);
  const noItemsAvailableForOrder = getNoItemsAvailableForOrder(state);
  const isNew = state.__typename === "New";

  return (
    <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <SectionHeader header="Menu Details" />

      <Row sx={{ gap: 2 }}>
        <TextField
          label="Meal Name"
          autoFocus
          value={state.name}
          sx={{ width: 512 }}
          onChange={e =>
            dispatch({
              type: "MenuChangeNameAction",
              payload: {
                name: e.target.value,
              },
            })
          }
        />
        <MealTypeSelect
          value={state.type}
          onChange={type =>
            dispatch({
              type: "MenuChangeMealTypeAction",
              payload: {
                type,
              },
            })
          }
          sx={{ minWidth: 120 }}
          menuName={state.name}
        />

        {!isNew && state.identity && <MealMenuIdentityChip mealMenuIdentity={state.identity} onOverride={onOverrideMealMenuIdentity} />}
        {state.shareState.__typename === "Teams" && (
          <TeamMultiSelect
            allTeams={allTeams}
            selectedTeams={state.shareState.teams}
            onChange={teams =>
              dispatch({
                type: "MenuChangeTeamsAction",
                payload: {
                  teams,
                },
              })
            }
            sx={{ minWidth: 120 }}
          />
        )}
        {state.shareState.__typename === "Tags" && (
          <ShareWithInfo
            state={state.shareState.advancedSelectionState}
            onClear={() => dispatch({ type: "MenuClearAdvancedSelectionAction" })}
            maxChips={4}
            containerSx={{ minWidth: `250px` }}
            showAdvancedSelectionClear={showAdvancedSelectionClear}
            showWrapper
          />
        )}

        <Button onClick={() => setAdvancedSelectOpen(true)} variant="outlined">
          {state.shareState.__typename === "Teams" ? "Advanced Selection" : "Edit Advanced Selection"}
        </Button>
      </Row>

      <SubHeader sx={{ gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
          {isNew ? (
            <StartDatesMultiSelect
              onChange={startDates =>
                dispatch({
                  type: "MenuChangeStartDatesAction",
                  payload: {
                    startDates,
                  },
                })
              }
              dates={state.startDates}
            />
          ) : (
            <DesktopDatePicker
              sx={{ width: 170 }}
              label="Date"
              onChange={d => {
                const startDate = d && safeSerializeDate(d);
                if (startDate) {
                  dispatch({
                    type: "MenuChangeStartDateAction",
                    payload: {
                      startDate,
                    },
                  });
                }
              }}
              value={parseDate(state.startDate)}
            />
          )}

          <StartTimeSelect
            label="Start"
            value={state.startTime}
            onChange={startTime =>
              dispatch({
                type: "MenuChangeStartTimeAction",
                payload: {
                  startTime,
                },
              })
            }
          />
          <EndTimeSelect
            value={state.durationInMinutes}
            startTime={state.startTime}
            onChange={durationInMinutes =>
              dispatch({
                type: "MenuChangeDurationInMinutesAction",
                payload: {
                  durationInMinutes,
                },
              })
            }
          />
          <IconButton sx={{ pl: 0, ml: -1 }} onClick={() => setEndTimeClockOpen(true)}>
            <AccessTimeIcon />
          </IconButton>
          <EndTimeModal
            open={endTimeClockOpen}
            setOpen={setEndTimeClockOpen}
            dispatch={dispatch}
            startTime={state.startTime}
            durationInMinutes={state.durationInMinutes}
          />
          <TimezoneSelect
            value={state.timezone}
            onChange={timezone =>
              dispatch({
                type: "MenuChangeTimezoneAction",
                payload: {
                  timezone,
                },
              })
            }
          />
        </Box>

        {isHubCheckInEnabled && (
          <HubCheckIn
            isHubCheckInEnabled={state.isHubCheckInEnabled}
            onChange={isHubCheckInEnabled =>
              dispatch({
                type: "MenuChangeHubCheckInEnabledAction",
                payload: {
                  isHubCheckInEnabled,
                },
              })
            }
          />
        )}
      </SubHeader>

      <SectionHeader
        header="Order Details"
        subcopy="“Prep Time” is the minimum time after order is placed before order can be picked up. “Prep Time” plus “Last Order Due” is after
          meal end time."
      />

      <SubHeader>
        <LastOrderDaySelect
          sx={{ width: 170 }}
          onChange={lastOrderDaysBefore =>
            dispatch({
              type: "MenuChangeLastOrderDaysBeforeAction",
              payload: {
                lastOrderDaysBefore,
              },
            })
          }
          value={state.lastOrderDaysBefore}
          showNextDay={endsOnDifferentDay(state)}
        />
        <StartTimeSelect
          label="Time"
          value={state.lastOrderTime}
          onChange={lastOrderTime =>
            dispatch({
              type: "MenuChangeLastOrderTimeAction",
              payload: {
                lastOrderTime,
              },
            })
          }
          sx={{ ml: 2 }}
          error={lastOrderStatus === "invalid"}
          warning={lastOrderStatus === "potential"}
        />
        <NotificationSettingsButton
          value={state.notificationSentBeforeOrderDueInMinutes}
          onChange={notificationSentBeforeOrderDueInMinutes =>
            dispatch({
              type: "MenuChangeNotificationSentBeforeOrderDueInMinutesAction",
              payload: {
                notificationSentBeforeOrderDueInMinutes,
              },
            })
          }
          sx={{ ml: 2 }}
          noItemsAvailableForOrder={noItemsAvailableForOrder}
        />
        <OrderRateLimitButton
          orderRateLimit={state.orderRateLimit}
          onChangeOrderRateLimit={orderRateLimit =>
            dispatch({
              type: "MenuChangeOrderRateLimitAction",
              payload: { orderRateLimit },
            })
          }
          teams={allTeams}
          sx={{ ml: 2 }}
        />
        <PrepTimeSelect
          value={state.prepTimeInMinutes}
          onChange={prepTimeInMinutes =>
            dispatch({
              type: "MenuChangePrepTimeInMinutesAction",
              payload: {
                prepTimeInMinutes,
              },
            })
          }
        />
        <DiningOptionSelect
          value={state.isDiningOptionEnabled}
          sx={{ ml: 2, mr: 1 }}
          onChange={diningOption => {
            trackEvent(
              `Nutrition | Kitchen | Menu Schedule | ${isNew ? "Create" : "Edit"} Menu | ${
                diningOption ? "DiningOptionEnabled" : "DiningOptionNotEnabled"
              }`,
              { name: state.name }
            );
            dispatch({
              type: "MenuChangeEnableDiningOptionAction",
              payload: {
                enableDiningOption: diningOption,
              },
            });
          }}
        />
      </SubHeader>
      {isDietitianOnlyMenusEnabled && (
        <SubHeader>
          <RestrictAccessToggle
            checked={state.isOrderAndLogRestricted}
            onChange={checked => {
              dispatch({
                type: "MenuChangeIsOrderAndLogRestrictedAction",
                payload: { isOrderAndLogRestricted: checked },
              });
            }}
          />
        </SubHeader>
      )}
      {advancedSelectOpen && (
        <AdvancedSelectionDialog
          title="Share Menu"
          open={advancedSelectOpen}
          onClose={() => setAdvancedSelectOpen(false)}
          onSave={advancedSelectionState => {
            dispatch({ type: "MenuChangeAdvancedSelectionAction", payload: { advancedSelectionState } });
            setAdvancedSelectOpen(false);
          }}
          initialState={
            state.shareState.__typename === "Teams"
              ? buildInitialCreateAdvancedSelectionStateFromTeams(state.shareState.teams)
              : state.shareState.advancedSelectionState
          }
        />
      )}
    </CardContent>
  );
};

interface EndTimeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  dispatch: (action: MenuDialogAction) => void;
  startTime: string;
  durationInMinutes: number;
}

const EndTimeModal = ({ open, setOpen, dispatch, startTime, durationInMinutes }: EndTimeModalProps) => {
  const startDateTime = parseTime(startTime);
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <TimeClock
          ampmInClock
          value={addMinutes(startDateTime, durationInMinutes)}
          onChange={dialogTime => {
            dispatch({
              type: "MenuChangeDurationInMinutesAction",
              payload: {
                durationInMinutes: dialogTime ? differenceInMinutes(dialogTime, startDateTime) : 0,
              },
            });
          }}
          minTime={addMinutes(startDateTime, 15)}
        />
      </DialogContent>
    </Dialog>
  );
};
