import { Box, Chip, Divider, Typography, capitalize } from "@mui/material";
import { grayBackground } from "@notemeal/palette";
import { useHasFeature } from "@notemeal/shared-ui";
import { formatTime } from "@notemeal/utils-date-time";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { Controller, useFieldArray } from "react-hook-form";
import NotificationSettingsButton from "../../Dialog/NotificationSettingsButton";
import OrderRateLimitButton from "../../Dialog/OrderRateLimit/Button";
import DiningOptionSelect from "../../SelectComponents/DiningOptionSelect";
import { ImportHubCheckInCheckbox } from "../../SelectComponents/ImportHubCheckInCheckbox";
import MenuLastOrderDaySelect from "../../SelectComponents/LastOrderDaySelect";
import PrepTimeSelect from "../../SelectComponents/PrepTimeSelect";
import StartTimeSelect from "../../SelectComponents/StartTimeSelect";
import UserOrderLimit from "../../SelectComponents/UserOrderLimit";
import ImportDiningStationCheckbox from "./DiningStationCheckbox";
import { ImportPlannedMenuType } from "./ImportMenuSchema";
import { useImportPlannedMenu } from "./ImportPlannedMenuContext";
import { BORDER_COLOR } from "./ImportPlannedMenuFormWeek";

interface ImportPlannedMenuFormMealProps {
  weekFormIndex: number;
  mealFormIndex: number;
}

export const ImportPlannedMenuFormMeal = ({ weekFormIndex, mealFormIndex }: ImportPlannedMenuFormMealProps) => {
  const { allTeams, plannedMenuWeeksWithMeals, form } = useImportPlannedMenu();
  const { control } = form;
  const { errors } = form.formState;
  const isHubCheckInEnabled = useHasFeature("hubMenuCheckIn");

  const meal = plannedMenuWeeksWithMeals[weekFormIndex].meals[mealFormIndex];
  const currentRowErrors = errors?.weeks?.[weekFormIndex]?.meals?.[mealFormIndex];

  const diningStationsArray = useFieldArray<ImportPlannedMenuType>({
    control: form.control,
    name: `weeks.${weekFormIndex}.meals.${mealFormIndex}.diningStations`,
  });

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: BORDER_COLOR,
        borderRadius: "6px",
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="body1Semibold">{`${meal.name} (${formatTime(meal.startTime, {
          alwaysShowMinutes: true,
        })} - ${formatTime(meal.endTime, { alwaysShowMinutes: true })})`}</Typography>
        <Chip sx={{ height: "22px" }} label={capitalize(meal.type)} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, py: 1 }}>
        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.lastOrderDueDaysBefore`}
          render={({ field: { ref, ...field } }) => <MenuLastOrderDaySelect
            {...field}
            showNextDay={false}
            sx={{ width: `160px` }} />}
        />
        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.lastOrderDueTime`}
          render={({ field: { ref, ...field } }) => (
            <StartTimeSelect
              {...field}
              label="Time"
              error={Boolean(currentRowErrors?.lastOrderDueTime)}
              sx={{ width: `160px` }} />
          )}
        />
        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.prepTimeInMinutes`}
          render={({ field: { ref, ...field } }) => <PrepTimeSelect {...field} />}
        />

        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.notificationSentBeforeOrderDueInMinutes`}
          render={({ field: { ref, ...field } }) => <NotificationSettingsButton {...field} sx={{ width: "100px", mt: 2 }} />}
        />
        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.orderRateLimit`}
          render={({ field: { ref, ...field } }) => (
            <OrderRateLimitButton
              {...field}
              onChangeOrderRateLimit={field.onChange}
              orderRateLimit={field.value}
              teams={allTeams}
              sx={{ width: "200px", mt: 2 }}
            />
          )}
        />
        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.userOrderLimit`}
          render={({ field: { ref, ...field } }) => (
            <UserOrderLimit
              {...field}
              limit={field.value}
              onChange={value => field.onChange({ limit: value !== null ? parseInt(value) : null })}
              sx={{ width: "170px", mt: 2 }}
            />
          )}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {isHubCheckInEnabled && (
          <Controller
            control={control}
            name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.isHubCheckInEnabled`}
            render={({ field: { ref, ...field } }) => <ImportHubCheckInCheckbox onChange={field.onChange} />}
          />
        )}

        <Controller
          control={control}
          name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.isDiningOptionEnabled`}
          render={({ field: { ref, ...field } }) => (
            <DiningOptionSelect
              labelVariant="body2"
              onChange={value => {
                trackEvent(`Nutrition | Kitchen | Menu Builder | Publish | ${value ? "DiningOptionEnabled" : "DiningOptionNotEnabled"}`, {
                  name: meal.name,
                });
                field.onChange(value);
              }}
            />
          )}
        />
      </Box>

      <Divider sx={{ pt: 0.5 }} />
      <Box sx={{ display: "flex", flexDirection: "column", pt: 2, gap: 2 }}>
        {diningStationsArray.fields.map((diningStation, index) => (
          <Box
            key={diningStation.id}
            sx={{
              backgroundColor: grayBackground,
              display: "flex",
              gap: 2,
              px: 1,
              borderRadius: "6px",
              alignItems: "center",
              minHeight: "42px",
            }}
          >
            <Typography variant="body1Medium" sx={{ minWidth: "150px" }}>
              {form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.diningStations.${index}.name`)}
            </Typography>
            {/* hide checkboxes when a dining station doesn't have items */}
            {form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.diningStations.${index}.hasMenuItems`) && (
              <>
                <Controller
                  name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.diningStations.${index}.availableForOrder`}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <ImportDiningStationCheckbox
                      value={field.value}
                      onChange={value => (value ? field.onChange("all") : field.onChange("none"))}
                      text="available for order"
                    />
                  )}
                />
                <Controller
                  name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.diningStations.${index}.allowSpecialRequests`}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <ImportDiningStationCheckbox
                      value={field.value}
                      onChange={value => (value ? field.onChange("all") : field.onChange("none"))}
                      text="allow special requests"
                    />
                  )}
                />
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
