import {
  AnthropometryEntryTypeSchema,
  applyInvalidMassValueError,
  BoneMineralDensityZScoreSchema,
  DateSchema,
  FloatSchema,
  IntegerSchema,
  PercentBodyFatSchema,
  VisceralFatAreaSchema,
} from "@notemeal/validators";
import { z } from "zod";
import {
  AnthropometryEntryFormInput,
  CreateAnthropometryEntryInput,
  CreateMetricAnthropometryEntryInput,
  EditAnthropometryEntryInput,
  EditMetricAnthropometryEntryInput,
  MetricAnthropometryEntryFormInput,
  SexType,
} from "../../../types";
import { measurementConversionToMetric, roundToHundredthsFloor } from "@notemeal/shared-utils-macro-protocol";

export const BaseAnthropometryEntrySchema = z.object({
  id: z.string().optional(),
  height: IntegerSchema.nullable().optional(),
  weight: FloatSchema,
  leanBodyMass: FloatSchema.nullable().optional(),
  leanBodyMassIsRequired: z.boolean(),
  percentBodyFat: PercentBodyFatSchema.nullable().optional(),
  type: AnthropometryEntryTypeSchema,
  date: DateSchema,
  boneMass: FloatSchema.nullable().optional(),
  bodyFatMass: FloatSchema.nullable().optional(),
  boneMineralDensityZScore: BoneMineralDensityZScoreSchema.nullable().optional(),
  dryLeanMass: FloatSchema.nullable().optional(),
  skeletalMuscleMass: FloatSchema.nullable().optional(),
  trunkFat: FloatSchema.nullable().optional(),
  visceralFatArea: VisceralFatAreaSchema.nullable().optional(),
  comment: z.string().nullable().optional(),
});

export type AnthropometryEntrySchemaOptions = {
  overrides?: z.ZodObject<{}>,
  leanMassOrPercentSchemaRequired?: boolean,
};

export const AnthropometryEntrySchema = (options?: AnthropometryEntrySchemaOptions) =>
  BaseAnthropometryEntrySchema.merge(options?.overrides ?? z.object({})).superRefine((state, ctx) => {
    const { id, comment, type, date, weight, ...nonWeightFields } = state;

    if (Object.values(nonWeightFields).some(field => !!field) && !weight) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["weight"],
      });
    }

    if (state.leanBodyMassIsRequired && !state.leanBodyMass) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["leanBodyMass"],
      });
    }

    if (options?.leanMassOrPercentSchemaRequired && !state.leanBodyMass && !state.percentBodyFat) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required if no '% Body Fat'",
        path: ["leanBodyMass"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required if no 'Lean Body Mass'",
        path: ["percentBodyFat"],
      });
    }
    applyInvalidMassValueError(state.leanBodyMass, state.weight, ctx, "leanBodyMass");
    applyInvalidMassValueError(state.skeletalMuscleMass, state.weight, ctx, "skeletalMuscleMass");
    applyInvalidMassValueError(state.dryLeanMass, state.weight, ctx, "dryLeanMass");
    applyInvalidMassValueError(state.trunkFat, state.weight, ctx, "trunkFat");
    applyInvalidMassValueError(state.bodyFatMass, state.weight, ctx, "bodyFatMass");
  });

export type AnthropometryEntryType = z.infer<typeof BaseAnthropometryEntrySchema>;

export const anthropometryEntryFormDefaultValues = (): Partial<AnthropometryEntryType> => ({
  height: null,
  leanBodyMassIsRequired: false,
  leanBodyMass: null,
  bodyFatMass: null,
  boneMass: null,
  boneMineralDensityZScore: null,
  percentBodyFat: null,
  dryLeanMass: null,
  skeletalMuscleMass: null,
  trunkFat: null,
  visceralFatArea: null,
  type: "estimate",
  date: new Date(),
  comment: "",
});

export const roundImperialAnthroValues = ({
  leanBodyMass,
  bodyFatMass,
  height,
  dryLeanMass,
  skeletalMuscleMass,
  trunkFat,
  weight,
  ...rest
}: AnthropometryEntryType): AnthropometryEntryType => ({
  leanBodyMass: leanBodyMass && roundToHundredthsFloor(leanBodyMass),
  bodyFatMass: bodyFatMass && roundToHundredthsFloor(bodyFatMass),
  height: height && roundToHundredthsFloor(height),
  dryLeanMass: dryLeanMass && roundToHundredthsFloor(dryLeanMass),
  skeletalMuscleMass: skeletalMuscleMass && roundToHundredthsFloor(skeletalMuscleMass),
  trunkFat: trunkFat && roundToHundredthsFloor(trunkFat),
  weight: weight && roundToHundredthsFloor(weight),
  ...rest,
});

export const anthropometryEntryFormStateToFormInput = (
  {
    height,
    weight,
    type,
    leanBodyMass,
    date,
    percentBodyFat,
    bodyFatMass,
    boneMineralDensityZScore,
    dryLeanMass,
    skeletalMuscleMass,
    visceralFatArea,
    trunkFat,
    comment,
  }: AnthropometryEntryType,
  athleteSex: SexType
): AnthropometryEntryFormInput => ({
  height: height ?? null,
  weight,
  sex: athleteSex,
  type,
  datetime: date.toISOString(),
  leanBodyMass: leanBodyMass ?? null,
  percentBodyFat: percentBodyFat ?? null,
  bodyFatMass: bodyFatMass ?? null,
  boneMineralDensityZScore: boneMineralDensityZScore ?? null,
  dryLeanMass: dryLeanMass ?? null,
  skeletalMuscleMass: skeletalMuscleMass ?? null,
  visceralFatArea: visceralFatArea ?? null,
  trunkFat: trunkFat ?? null,
  comment: comment ?? "",
  boneMineralDensityTScore: null,
  boneArea: null,
  boneMass: null,
  boneMineralContent: null,
  boneMineralDensity: null,
});

export const anthropometryEntryFormStateToMetricFormInput = (
  {
    height,
    weight,
    type,
    leanBodyMass,
    date,
    percentBodyFat,
    bodyFatMass,
    boneMineralDensityZScore,
    dryLeanMass,
    skeletalMuscleMass,
    visceralFatArea,
    trunkFat,
    comment,
  }: AnthropometryEntryType,
  athleteSex: SexType
): MetricAnthropometryEntryFormInput => ({
  heightInCm: measurementConversionToMetric(false, height, "length"),
  weightInKg: measurementConversionToMetric(false, weight, "weight")!,
  sex: athleteSex,
  type,
  datetime: date.toISOString(),
  leanBodyMassInKg: measurementConversionToMetric(false, leanBodyMass, "weight"),
  percentBodyFat: percentBodyFat ?? null,
  bodyFatMassInKg: measurementConversionToMetric(false, bodyFatMass, "weight"),
  boneMineralDensityZScore: boneMineralDensityZScore ?? null,
  dryLeanMassInKg: measurementConversionToMetric(false, dryLeanMass, "weight"),
  skeletalMuscleMassInKg: measurementConversionToMetric(false, skeletalMuscleMass, "weight"),
  visceralFatArea: visceralFatArea ?? null,
  trunkFatInKg: measurementConversionToMetric(false, trunkFat, "weight"),
  comment: comment ?? "",
  boneMineralDensityTScore: null,
  boneArea: null,
  boneMass: null,
  boneMineralContent: null,
  boneMineralDensity: null,
});

export const anthropometryEntryFormStateToMetricCreateInput = (
  state: AnthropometryEntryType,
  athleteId: string,
  athleteSex: SexType
): CreateMetricAnthropometryEntryInput => ({
  athleteId,
  anthropometryEntry: anthropometryEntryFormStateToMetricFormInput(state, athleteSex),
});

export const anthropometryEntryFormStateToMetricEditInput = (
  state: AnthropometryEntryType & { id: string },
  athleteSex: SexType
): EditMetricAnthropometryEntryInput => ({
  id: state.id,
  anthropometryEntry: anthropometryEntryFormStateToMetricFormInput(state, athleteSex),
});

// TODO: metric update - to be deprecated and replaced by anthropometryEntryFormStateToMetricCreateInput()
export const anthropometryEntryFormStateToCreateInput = (
  state: AnthropometryEntryType,
  athleteId: string,
  athleteSex: SexType
): CreateAnthropometryEntryInput => ({
  athleteId,
  anthropometryEntry: anthropometryEntryFormStateToFormInput(state, athleteSex),
});

export const anthropometryEntryFormStateToEditInput = (
  state: AnthropometryEntryType & { id: string },
  athleteSex: SexType
): EditAnthropometryEntryInput => ({
  id: state.id,
  anthropometryEntry: anthropometryEntryFormStateToFormInput(state, athleteSex),
});
