import { PlannedMenuWeekForImportFragment } from "apps/web/src/types";
import { z } from "zod";
import { getDiningStationsFromMealRows } from "./utils";

const OrderRateLimitSchema = z.object({ excludedTeamIds: z.array(z.string()), limit: z.number() });
const DiningStationSchema = z.object({
  id: z.string(),
  name: z.string(),
  hasMenuItems: z.boolean(),
  availableForOrder: z.enum(["all", "none", "some"]),
  allowSpecialRequests: z.enum(["all", "none", "some"]),
});
const UserOrderLimitSchema = z.object({ limit: z.number().nullable() });

export const MealSchema = z.object({
  id: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  lastOrderDueDaysBefore: z.number(),
  lastOrderDueTime: z.string(),
  prepTimeInMinutes: z.number(),
  notificationSentBeforeOrderDueInMinutes: z.number().nullable(),
  orderRateLimit: OrderRateLimitSchema.nullable(),
  userOrderLimit: UserOrderLimitSchema.nullable(),
  isHubCheckInEnabled: z.boolean(),
  diningStations: z.array(DiningStationSchema),
  themes: z.array(z.string()),
  isDiningOptionEnabled: z.boolean(),
});

const PlannedMenuWeekWithMeals = z.object({ id: z.string(), weekNumber: z.number(), meals: z.array(MealSchema) });

const PlannedMenu = z.object({
  id: z.string(),
  name: z.string(),
});

export const ImportPlannedMenuFormSchema = z.object({
  plannedMenu: PlannedMenu,
  weeks: z.array(PlannedMenuWeekWithMeals),
});

export const ImportPlannedMenuFormMealsSchema = z.object({
  weeks: z.array(PlannedMenuWeekWithMeals),
});

export type ImportPlannedMenuType = z.infer<typeof ImportPlannedMenuFormSchema>;
export type ImportPlannedMenuMealType = z.infer<typeof MealSchema>;
export type ImportPlannedMenuWeeksType = z.infer<typeof ImportPlannedMenuFormMealsSchema>;

export const getImportPlannedMenuHeaderDefaultValues = (plannedMenu?: { name: string; id: string }): Partial<ImportPlannedMenuType> => ({
  plannedMenu: plannedMenu ? { id: plannedMenu.id, name: plannedMenu.name } : undefined,
  weeks: [],
});

export const getImportPlannedMenuDefaultValues = (
  plannedMenuWeeksWithMeals: readonly PlannedMenuWeekForImportFragment[]
): ImportPlannedMenuWeeksType => {
  const weeks = plannedMenuWeeksWithMeals.map(week => ({
    id: week.id,
    weekNumber: week.week,
    meals: week.meals.map(meal => ({
      id: meal.id,
      startTime: meal.startTime,
      endTime: meal.endTime,
      lastOrderDueDaysBefore: 0,
      lastOrderDueTime: meal.endTime,
      prepTimeInMinutes: 15,
      notificationSentBeforeOrderDueInMinutes: null,
      orderRateLimit: null,
      userOrderLimit: null,
      isHubCheckInEnabled: false,
      themes: [...meal.themes],
      diningStations: getDiningStationsFromMealRows(meal.plannedMenuMealRows).map(station => {
        return {
          id: station.id,
          name: station.name,
          hasMenuItems: station.items.length > 0,
          availableForOrder: reduceMenuItemField(station.items.map(item => item.menuItem.defaultAvailableForOrder)),
          allowSpecialRequests: reduceMenuItemField(station.items.map(item => item.menuItem.defaultAllowSpecialRequests)),
        };
      }),
      isDiningOptionEnabled: false,
    })),
  }));
  return { weeks };
};

const reduceMenuItemField = (itemFields: boolean[]) => {
  if (itemFields.every(x => x)) {
    return "all" as const;
  } else {
    return itemFields.some(x => x) ? ("some" as const) : ("none" as const);
  }
};
