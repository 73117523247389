import { useState } from "react";
import { AssignmentRounded } from "@mui/icons-material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared-ui";
import { BulkAssignMealPlanTemplateJobOffsetConnectionQuery } from "apps/web/src/types";
import { getMuiTablePaginationProps, PaginationHooks } from "apps/web/src/utils/pagination";
import { format } from "date-fns";
import { TWChip, TWChipColor } from "apps/web/src/componentLibrary/TWChip/TWChip";
import { BulkAssignMealPlanTemplateJobStatus } from "@notemeal/graphql-types";
import { BulkAssignMealPlanTemplateStatusModal } from "./BulkAssignMealPlanTemplateStatusModal";

export type SelectedJob = {
  id: string;
  name: string;
  dateAssignment: string;
};

const getJobStatus = (
  job: BulkAssignMealPlanTemplateJobOffsetConnectionQuery["bulkAssignMealPlanTemplateJobOffsetConnection"]["edges"][0]
) => {
  switch (job.status) {
    case BulkAssignMealPlanTemplateJobStatus.success:
      return <TWChip label="Success" chipColor={TWChipColor.green} />;
    case BulkAssignMealPlanTemplateJobStatus.partialSuccess:
      return <TWChip label="Partial Success" chipColor={TWChipColor.orange} />;
    case BulkAssignMealPlanTemplateJobStatus.failed:
      return <TWChip label="Failed" chipColor={TWChipColor.red} />;
    case BulkAssignMealPlanTemplateJobStatus.processing:
      return <TWChip label="In Progress" chipColor={TWChipColor.blue} />;
    case BulkAssignMealPlanTemplateJobStatus.queued:
    default:
      return <TWChip label="Waiting" chipColor={TWChipColor.gray} />;
  }
};

type BulkAssignMealPlanTemplateStatusProps = {
  loadBulkAssignMealPlanTemplateJobOffset: () => void;
  pagination: PaginationHooks;
  loading: boolean;
  data: BulkAssignMealPlanTemplateJobOffsetConnectionQuery | undefined;
};

export const BulkAssignMealPlanTemplateStatus = ({
  loadBulkAssignMealPlanTemplateJobOffset,
  pagination,
  loading,
  data,
}: BulkAssignMealPlanTemplateStatusProps) => {
  const { dateFnsLocale } = useDateFormatting();
  const [selectedJob, setSelectedJob] = useState<SelectedJob | null>(null);

  if (!loading && !data) {
    loadBulkAssignMealPlanTemplateJobOffset();
    return <Typography>Loading...</Typography>;
  }
  if (loading || !data) {
    return <Typography>Loading...</Typography>;
  }

  if (data.bulkAssignMealPlanTemplateJobOffsetConnection.edges.length === 0) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", flex: 1 }}>
        <AssignmentRounded fontSize="extraLarge" />
        <Typography variant="h4" sx={{ mt: 1, textAlign: "center" }}>
          No assignments
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, textAlign: "center", pb: 8 /* spacing for visual centering */ }}>
          No meal plan templates have been assigned to athletes yet.
          <br />
          Get started by creating and assigning one today.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ px: 3, py: 1.25 }}>Name</TableCell>
              <TableCell sx={{ px: 3, py: 1.25, width: 200 }}>Assigned By</TableCell>
              <TableCell sx={{ px: 3, py: 1.25, width: 160 }}>Date Assigned</TableCell>
              <TableCell sx={{ px: 3, py: 1.25, width: 160 }}>Assignment</TableCell>
              <TableCell sx={{ px: 3, py: 1.25 }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.bulkAssignMealPlanTemplateJobOffsetConnection.edges.map(job => (
              <TableRow
                key={job.id}
                onClick={() =>
                  setSelectedJob({
                    id: job.id,
                    name: job.name,
                    dateAssignment: job.dateAssignment,
                  })
                }
                hover
                sx={{
                  cursor: "pointer",
                  height: 48,
                }}
              >
                <TableCell sx={{ px: 3, py: 1.5 }}>{job.name}</TableCell>
                <TableCell sx={{ px: 3, py: 1.5 }}>
                  {job.createdBy?.firstName} {job.createdBy?.lastName}
                </TableCell>
                <TableCell sx={{ px: 3, py: 1.5 }}>{format(new Date(job.createdAt), "MM/dd/yyyy", { locale: dateFnsLocale })}</TableCell>
                <TableCell sx={{ px: 3, py: 1.5 }}>
                  {job.athleteSucceededCount + job.athleteFailedCount}/{job.athleteCount}
                </TableCell>
                <TableCell sx={{ px: 3, py: 1.5 }}>{getJobStatus(job)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        {...getMuiTablePaginationProps(pagination, data.bulkAssignMealPlanTemplateJobOffsetConnection.pageInfo.total)}
      />
      {selectedJob && (
        <BulkAssignMealPlanTemplateStatusModal
          open={!!selectedJob}
          onClose={() => setSelectedJob(null)}
          selectedJob={selectedJob} />
      )}
    </Box>
  );
};
