import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, ListItemButton, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AthleteForMealPlanTemplateAssignmentFragment } from "apps/web/src/types";
import NamedTagChipList from "apps/web/src/views/Tags/NamedTagChipList";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { rmrErrorLookup } from "libs/shared/utils/macro-protocol/dist";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      marginRight: theme.spacing(1),
    },
    innerContainer: {
      display: "flex",
      flex: 1,
    },
    outerContainer: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    close: {
      color: theme.palette.grey[500],
    },
    chip: {
      cursor: "pointer",
    },
  })
);

interface BulkAssignMealPlanTemplateAthleteListItemProps {
  athlete: AthleteForMealPlanTemplateAssignmentFragment;
  onClick: (athlete: AthleteForMealPlanTemplateAssignmentFragment) => void;
  onClickNoAnthropometryItem?: (athlete: AthleteForMealPlanTemplateAssignmentFragment) => void;
  sx?: SxProps;
  showRemove?: boolean;
}

export const AthleteItemContent = ({ athlete }: { athlete: AthleteForMealPlanTemplateAssignmentFragment }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.name}>{`${athlete.lastName}, ${athlete.firstName} `}</Typography>
      <NamedTagChipList
        namedTags={athlete.myNamedTagsOffsetConnection.edges}
        namedTagsCount={athlete.myNamedTagsOffsetConnection.pageInfo.total}
        chipClassName={classes.chip}
      />
    </>
  );
};

export const BulkAssignMealPlanTemplateAthleteListItem = ({
  athlete,
  onClick,
  onClickNoAnthropometryItem,
  sx,
  showRemove,
}: BulkAssignMealPlanTemplateAthleteListItemProps) => {
  const { anthropometryEntry, failureReason } = athlete.mostRecentAnthropometryEntryForRmrMethodOrFailureReason;

  const humanReadableError = failureReason?.includes("ERROR_AGE_REQUIRED") ? rmrErrorLookup.get(failureReason) : failureReason;
  const renderListItemBase = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer", alignItems: "center" }}>
        <ListItemButton
          disabled={failureReason !== null}
          onClick={() => onClick(athlete)}
          sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer", ...sx }}
        >
          <Box sx={{ display: "flex", flex: 1 }}>
            <AthleteItemContent athlete={athlete} />
          </Box>
          {showRemove && <CloseIcon sx={{ color: "grey.dark" }} />}
        </ListItemButton>
        {failureReason && onClickNoAnthropometryItem && (
          <IconButton onClick={() => onClickNoAnthropometryItem(athlete)} color="secondary"><EditIcon /></IconButton>
        )}
      </Box>
    )
  };
  return failureReason ? (
    <TWTooltip
      title={"Cannot assign meal plan template to athlete"}
      subcopy={`Anthropometry data insufficent: ${humanReadableError}`}
      placement="bottom"
    >
      {renderListItemBase()}
    </TWTooltip>
    ) : (
    renderListItemBase()
  );
};
