import React from "react";
import { Chip, useTheme } from "@mui/material";
import { blueDark, green, greyBlue, orangeDark } from "../../pages/Theme/Colors/Accents";
export enum TWChipColor {
  "green",
  "blue",
  "orange",
  "red",
  "gray",
}

type TWChipProps = {
  label: string;
  chipColor: TWChipColor;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  smLabel?: boolean;
};

export const TWChip = ({ label, chipColor, icon, smLabel }: TWChipProps) => {
  const {
    palette: { error },
  } = useTheme();
  const getStyleColors = (style: TWChipColor) => {
    switch (style) {
      case TWChipColor.green:
        return { background: green[50], color: green[700] };
      case TWChipColor.blue:
        return { background: blueDark[50], color: blueDark[700] };
      case TWChipColor.orange:
        return { background: orangeDark[50], color: orangeDark[500] };
      case TWChipColor.red:
        return { background: error.lighter, color: error.main };
      case TWChipColor.gray:
      default:
        return { background: greyBlue[100], color: greyBlue[700] };
    }
  };
  const { background, color } = getStyleColors(chipColor);

  return (
    <Chip
      icon={icon}
      sx={{
        background,
        padding: "3px 8px",
        "& .MuiChip-label": {
          color,
          fontWeight: smLabel ? 500 : 600,
          fontSize: smLabel ? 11 : 14,
          padding: 0,
          "&:first-letter": {
            textTransform: "uppercase",
          },
        },
        "& .MuiChip-icon": {
          color,
          minWidth: "min-content",
          paddingRight: smLabel ? 0.8 : 1,
          fontSize: smLabel ? 16 : 18,
        },
      }}
      variant="filled"
      size="small"
      label={label}
    />
  );
};
