import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow, TableSortLabel } from "@mui/material";
import { useHasFeature } from "@notemeal/shared-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { ConfirmationDialog } from "../../componentLibrary";
import { TWChip, TWChipColor } from "../../componentLibrary/TWChip/TWChip";
import { AthleteAssignState } from "../../components/AthleteAssignment/athleteAssignReducer";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";
import { getMealPlanAssignTemplate } from "../../pages/Auth/Org/MealPlans/MealPlansPaths";
import {
  DietitianMealPlanTemplateListItemFragment,
  GoalTypeFragment,
  TeamsPickerTeamFragment,
  useCopyMacroMealPlanTemplateMutation,
  useDeleteMacroMealPlanTemplateMutation,
  useDietitianMealPlanTemplateOffsetConnectionQuery,
  useMoveMacroMealPlanTemplateToOrgGroupMutation,
} from "../../types";
import { PaginationHooks } from "../../utils/pagination";
import { useUser } from "../../utils/tokens";
import AssignInfoCell from "./AssignInfoCell";
import BaseMealPlanTemplates from "./BaseMealPlanTemplate";
import { EditOrgMealPlanTemplate } from "./EditOrgMealPlanTemplate";
import ShareTableCell from "./ShareTableCell";

interface OrgMealPlanTemplateHeaderRowProps {
  direction: "desc" | "asc";
  toggleSortOrder: () => void;
}

const OrgMealPlanTemplateHeaderRow = ({ direction, toggleSortOrder }: OrgMealPlanTemplateHeaderRowProps) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: 100,
        }}
      >
        Shared
      </TableCell>
      <TableCell>
        <TableSortLabel direction={direction} onClick={() => toggleSortOrder()}>
          Name
        </TableSortLabel>
      </TableCell>
      <TableCell>Share With</TableCell>
      <TableCell
        sx={{
          width: 150,
        }}
      >
        Status
      </TableCell>
      <TableCell
        sx={{
          width: 50,
        }}
      >
        Actions
      </TableCell>
    </TableRow>
  );
};

interface OrgMealPlanTemplateRowProps {
  mealPlanTemplate: DietitianMealPlanTemplateListItemFragment;
  selectMealPlanTemplate: () => void;
  updateIsShared: (macroMealPlanTemplateId: string, isShared: boolean) => void;
  setEditMPTAssignInfo: (macroMealPlanTemplateId: string, initialState: AthleteAssignState<TeamsPickerTeamFragment>) => void;
  removeMealPlanTemplate: () => void;
  copyMealPlanTemplate: () => void;
  moveMealPlanTemplate: () => void;
  canMoveToOrgGroup: boolean;
}

const OrgMealPlanTemplateRow = ({
  mealPlanTemplate,
  selectMealPlanTemplate,
  updateIsShared,
  removeMealPlanTemplate,
  copyMealPlanTemplate,
  moveMealPlanTemplate,
  setEditMPTAssignInfo,
  canMoveToOrgGroup,
}: OrgMealPlanTemplateRowProps) => {
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const hasMealPlanTemplateBulkAssignments = useHasFeature("mealPlanTemplateBulkAssignments");
  const navigate = useNavigate();

  const { id, name, isShared, pendingState } = mealPlanTemplate;
  const hasPending = !!pendingState;
  const chipColor = hasPending ? TWChipColor.green : TWChipColor.blue;
  const chipLabel = hasPending ? "Pending Changes" : "All Changes Published";

  return (
    <>
      <TableRow
        id={id}
        onClick={() => selectMealPlanTemplate()}
        hover
        sx={{
          cursor: "pointer",
        }}
      >
        <ShareTableCell mealPlanTemplateId={id} isShared={isShared} updateIsShared={updateIsShared} />
        <TableCell>{name}</TableCell>
        <AssignInfoCell mealPlanTemplate={mealPlanTemplate} setEditMPTAssignInfo={setEditMPTAssignInfo} />
        <TableCell>
          <TWChip label={chipLabel} chipColor={chipColor} />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              setMoreAnchorEl(e.currentTarget);
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {hasMealPlanTemplateBulkAssignments && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);
              navigate(getMealPlanAssignTemplate(id));
            }}
          >
            Assign
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setMoreAnchorEl(null);
            copyMealPlanTemplate();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMoreAnchorEl(null);
            removeMealPlanTemplate();
          }}
        >
          Delete
        </MenuItem>
        {canMoveToOrgGroup && (
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);
              moveMealPlanTemplate();
            }}
          >
            Move to Org Group
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

interface OrgMealPlanTemplateProps {
  pagination: PaginationHooks;
  onCompleteAction: (action: string, name: string, resetPaging: boolean, mealPlanTemplateId?: string) => void;
  onSelectTemplateId: (selectedTemplateId: string | null) => void;
  selectedTemplateId: string | null;
  orgGroups: readonly { id: string; name: string }[];
  goalTypes: readonly GoalTypeFragment[];
}

export const OrgMealPlanTemplates = ({
  pagination,
  onCompleteAction,
  onSelectTemplateId,
  selectedTemplateId,
  orgGroups,
  goalTypes,
}: OrgMealPlanTemplateProps) => {
  const { setMessage } = useSnackbar();
  const [sortAscending, setSortAscending] = useState(true);
  const { limit, offset, query: debouncedSearchText, onChangePage } = pagination;
  const { data, loading } = useDietitianMealPlanTemplateOffsetConnectionQuery({
    variables: { pagination: { offset, limit }, searchText: debouncedSearchText, sortAscending },
  });

  const [copyMealPlanTemplate] = useCopyMacroMealPlanTemplateMutation({
    onCompleted: ({ copyMacroMealPlanTemplate: { macroMealPlanTemplate } }) => {
      onCompleteAction("copied", "", true);
      onChangePage(0);
      onSelectTemplateId(macroMealPlanTemplate.id);
    },
  });
  const rows = data?.mealPlanTemplateOffsetConnection.edges || [];
  const getSortOrder = () => (sortAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortAscending(!sortAscending);

  const [removeMealPlanTemplate] = useDeleteMacroMealPlanTemplateMutation({
    onCompleted: () => {
      onCompleteAction("removed", "", true);
      onChangePage(0);
    },
  });
  const [confirmDelete, setConfirmDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const onConfirmDelete = (macroMealPlanTemplateId: string) => {
    removeMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId } } });
    setConfirmDelete(null);
  };

  const [moveMealPlanTemplate] = useMoveMacroMealPlanTemplateToOrgGroupMutation({
    onCompleted: () => {
      onCompleteAction("moved", "", true);
      onChangePage(0);
    },
  });
  const [toMoveMealPlanTemplate, setToMoveMealPlanTemplate] = useState<DietitianMealPlanTemplateListItemFragment | null>(null);
  const onConfirmMove = (macroMealPlanTemplateId: string) => {
    if (orgGroups.length !== 1) {
      setMessage("error", "Moving Meal Plan Templates to Org Groups when Org belongs to multiple groups is not yet supported.");
    } else {
      moveMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId, orgGroupId: orgGroups[0].id } } });
    }
    setToMoveMealPlanTemplate(null);
  };

  const user = useUser();
  const isAdmin = user?.orgMembership?.isAdmin ?? false;

  return (
    <>
      <BaseMealPlanTemplates
        pagination={pagination}
        renderHeaderRow={() => <OrgMealPlanTemplateHeaderRow direction={getSortOrder()} toggleSortOrder={toggleSortOrder} />}
        mealPlanTemplateRows={rows.concat()}
        loading={loading}
        total={data?.mealPlanTemplateOffsetConnection.pageInfo.total || 0}
        renderMealPlanTemplateRow={(mealPlanTemplate, updateIsShared, setEditMPTAssignInfo) => (
          <OrgMealPlanTemplateRow
            key={mealPlanTemplate.id}
            mealPlanTemplate={mealPlanTemplate}
            selectMealPlanTemplate={() => onSelectTemplateId(mealPlanTemplate.id)}
            updateIsShared={updateIsShared}
            removeMealPlanTemplate={() => setConfirmDelete({ id: mealPlanTemplate.id, name: mealPlanTemplate.name })}
            copyMealPlanTemplate={() => copyMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId: mealPlanTemplate.id } } })}
            moveMealPlanTemplate={() => setToMoveMealPlanTemplate(mealPlanTemplate)}
            setEditMPTAssignInfo={setEditMPTAssignInfo}
            canMoveToOrgGroup={orgGroups.length > 0 && isAdmin}
          />
        )}
      />
      {selectedTemplateId && (
        <EditOrgMealPlanTemplate
          open={selectedTemplateId !== null}
          onEditSuccess={({ name }) => onCompleteAction("edited", name, false)}
          onDiscardEditSuccess={() => onCompleteAction("discarded edits", "", false)}
          onPublishSuccess={({ name }) => onCompleteAction("published", name, true)}
          copyMealPlanTemplate={() => copyMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId: selectedTemplateId } } })}
          removeMealPlanTemplate={({ name }) => setConfirmDelete({ id: selectedTemplateId, name })}
          onClose={() => onSelectTemplateId(null)}
          goalTypes={goalTypes}
          id={selectedTemplateId}
        />
      )}
      {confirmDelete && (
        <ConfirmationDialog
          open={!!confirmDelete}
          title="Delete Meal Plan Template?"
          message={`This action cannot be undone. Are you sure you want to delete the meal plan template “${confirmDelete.name}”?`}
          onCancel={() => setConfirmDelete(null)}
          onConfirm={() => onConfirmDelete(confirmDelete.id)}
          variant="containedDestructive"
        />
      )}
      {toMoveMealPlanTemplate && (
        <ConfirmationDialog
          open={!!toMoveMealPlanTemplate}
          title="Move Template"
          message={
            <>
              Are you sure you want to move the template to Org Group Templates? Doing so will:
              <ul>
                <li>Remove this template from being available for auto-onboarding within your org</li>
                <li>Allow other dietitians to view and duplicate it to their orgs</li>
              </ul>
            </>
          }
          onCancel={() => setToMoveMealPlanTemplate(null)}
          onConfirm={() => onConfirmMove(toMoveMealPlanTemplate.id)}
          confirmLabel="Yes"
        />
      )}
    </>
  );
};
