import { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDebounce } from "@notemeal/shared-ui";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { SelectedJob } from "./BulkAssignMealPlanTemplateStatus";
import { BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScroll } from "./BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScroll";
import {
  AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment,
  useBulkAssignMealPlanTemplateJobAthleteForStatusModalQuery,
} from "apps/web/src/types";
import useInfiniteCursorConnectionScroll from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { TWLastUpdatedBadge } from "apps/web/src/componentLibrary/TWLastUpdatedBadge/TWLastUpdatedBadge";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";

interface EditOrgMealPlanTemplateProps {
  open: boolean;
  onClose: () => void;
  selectedJob: SelectedJob;
}

export const BulkAssignMealPlanTemplateStatusModal = ({ open, onClose, selectedJob }: EditOrgMealPlanTemplateProps) => {
  const [queryLastRanTimestamp, setQueryLastRanTimestamp] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery, 200);

  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useBulkAssignMealPlanTemplateJobAthleteForStatusModalQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          jobId: selectedJob.id,
          query: debouncedQuery,
        },
      }),
      [debouncedQuery, selectedJob]
    ),
    queryKey: "athletesByBulkAssignMealPlanTemplateJobCursorConnection",
    edgesAreEqual: useCallback(
      (
        athlete1: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment,
        athlete2: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment
      ) => {
        return athlete1.id === athlete2.id;
      },
      []
    ),
    limit: 8,
  });

  useEffect(() => {
    setQueryLastRanTimestamp(Date.now());
  }, [selectedJob, debouncedQuery]);

  const parseAssignmentDates = () => {
    const assignmentDates = JSON.parse(selectedJob.dateAssignment);
    if (assignmentDates.individualDates) {
      const dates = assignmentDates.individualDates;
      if(dates.length > 6) {
        const additionalDates = dates.slice(6, dates.length).map((date: string) => new Date(date).toLocaleDateString()).join(", ");
        return (
          <Box component="span" sx={{ display: "flex", alignItems: "center", gap: .5 }}>
            Individual Dates | {dates.slice(0, 6).map((date: string) => new Date(date).toLocaleDateString()).join(", ")}
            <TWTooltip title="Additional dates" subcopy={additionalDates}><Box>...</Box></TWTooltip>
          </Box>
        )
      }
      return `Individual Dates | ${dates.map((date: string) => new Date(date).toLocaleDateString()).join(", ")}`;
    }
    const { daysOfWeek, startDate, endDate } = assignmentDates;
    const formattedDaysOfWeek = daysOfWeek.map((day: string) => day.slice(0, 1).toUpperCase()).join(", ");
    const formattedStartDate = new Date(startDate).toLocaleDateString();
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString() : "N/A";
    return `Weekly (${formattedDaysOfWeek}) | ${formattedStartDate} - ${formattedEndDate}`;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_: Object, reason: string) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      sx={{ "& .MuiPaper-root": { height: 800 } }}
    >
      <DialogTitle title="View Assignment Status" onClose={onClose} />
      <DialogContent sx={{ pt: 0, gap: 0 }}>
        <Box>
          <Typography variant="h4">{selectedJob.name}</Typography>
          <Typography variant="subtitle1" sx={{ pt: 0.5 }}>
            {parseAssignmentDates()}
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h4">Assignment Status</Typography>
        <Typography variant="subtitle1" sx={{ pt: 0.5, pb: 2 }}>
          Loading the assignment status for all athletes may take some time
        </Typography>
        <Box sx={{ pb: 3 }}>
          <TextField
            sx={{ width: 300 }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </Box>
        <BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScroll
          searchResults={searchResults}
          onClickItem={() => {
            // TODO: failed items should be retryable
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <TWLastUpdatedBadge lastUpdatedTimestamp={queryLastRanTimestamp} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            onClick={() => {
              setQueryLastRanTimestamp(Date.now());
              searchResults.refetch();
            }}
            variant="outlined"
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
          <Button
            autoFocus
            onClick={onClose}
            variant="contained">
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
