import { Box, Typography } from "@mui/material";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const BulkAssignMealPlanTemplateWarning = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        bgcolor: "#FEF4E6",
        gap: 1,
        p: 2,
        borderRadius: 1,
        overflow: "visible",
      }}
    >
      <WarningRoundedIcon sx={{ width: 22, height: 22 }} color="warning" />
      <Box>
        <Typography variant="h4">Meal plan template assignment in progress</Typography>
        <Typography>The athlete’s meal plans cannot be edited while meal plan template assignment is in progress</Typography>
      </Box>
    </Box>
  );
};
