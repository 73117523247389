import { MenuDialogState } from "./types";
import { MenuItemAppearanceGroupAction, menuItemAppearanceGroupReducer } from "../../components/MenuItemAppearance/reducer";
import {
  DeliveryLocationMenuLinkFormFragment,
  DiningStationTemplateFullFragment,
  MealType,
  MenuOrderRateLimitFragment,
  RestaurantLocationPreviewFragment,
  RestaurantMenuFormFragment,
  RestaurantPreviewFragment,
  TeamMealMenuPreviewFragment,
} from "../../types";
import { newId } from "@notemeal/shared-ui";
import { getNextPosition } from "@notemeal/shared-ui";
import { RestaurantMenuLinkAction, restaurantMenuLinkReducer } from "./RestaurantMenu/reducer";
import { MealMenuDiningStationState } from "./DiningStation/types";
import { getAddRestaurantMenuLinkState, getInitialSelectedRestaurantMenuLinkId } from "./RestaurantMenu/utils";
import { resetLastOrderDue } from "./Dialog/utils";
import { AdvancedSelectionState, isAdvancedSelectionEmpty } from "../Tags/reducers/advancedSelectionReducers";
import { getEmptyShareState } from "./utils";
import { ShareMealMenuState } from "./types";
interface MenuChangeNameAction {
  type: "MenuChangeNameAction";
  payload: {
    name: string;
  };
}

interface MenuChangeMealTypeAction {
  type: "MenuChangeMealTypeAction";
  payload: {
    type: MealType;
  };
}

interface MenuChangeStartDatesAction {
  type: "MenuChangeStartDatesAction";
  payload: {
    startDates: string[];
  };
}

interface MenuChangeStartDateAction {
  type: "MenuChangeStartDateAction";
  payload: {
    startDate: string;
  };
}

export interface MenuChangeStartTimeAction {
  type: "MenuChangeStartTimeAction";
  payload: {
    startTime: string;
  };
}

export interface MenuChangeDurationInMinutesAction {
  type: "MenuChangeDurationInMinutesAction";
  payload: {
    durationInMinutes: number;
  };
}

export interface MenuChangeTimezoneAction {
  type: "MenuChangeTimezoneAction";
  payload: {
    timezone: string;
  };
}

export interface MenuChangeLastOrderDaysBeforeAction {
  type: "MenuChangeLastOrderDaysBeforeAction";
  payload: {
    lastOrderDaysBefore: number;
  };
}

export interface MenuChangeLastOrderTimeAction {
  type: "MenuChangeLastOrderTimeAction";
  payload: {
    lastOrderTime: string;
  };
}

interface MenuChangePrepTimeInMinutesAction {
  type: "MenuChangePrepTimeInMinutesAction";
  payload: {
    prepTimeInMinutes: number;
  };
}

interface MenuChangeNotificationSentBeforeOrderDueInMinutesAction {
  type: "MenuChangeNotificationSentBeforeOrderDueInMinutesAction";
  payload: {
    notificationSentBeforeOrderDueInMinutes: number | null;
  };
}

interface MenuChangeOrderRateLimitAction {
  type: "MenuChangeOrderRateLimitAction";
  payload: {
    orderRateLimit: MenuOrderRateLimitFragment | null;
  };
}

interface MenuChangeHubCheckInEnabledAction {
  type: "MenuChangeHubCheckInEnabledAction";
  payload: {
    isHubCheckInEnabled: boolean;
  };
}

interface MenuChangeIsOrderAndLogRestrictedAction {
  type: "MenuChangeIsOrderAndLogRestrictedAction";
  payload: {
    isOrderAndLogRestricted: boolean;
  };
}

interface MenuChangeAdvancedSelectionAction {
  type: "MenuChangeAdvancedSelectionAction";
  payload: {
    advancedSelectionState: AdvancedSelectionState;
  };
}
interface MenuClearAdvancedSelectionAction {
  type: "MenuClearAdvancedSelectionAction";
}

interface MenuChangeTeamsAction {
  type: "MenuChangeTeamsAction";
  payload: {
    teams: readonly TeamMealMenuPreviewFragment[];
  };
}

interface MenuDiningStationChangeNameAction {
  type: "MenuDiningStationChangeNameAction";
  payload: {
    mealMenuDiningStationId: string;
    name: string;
  };
}

interface MenuDiningStationChangeMaxAmountAction {
  type: "MenuDiningStationChangeMaxAmountAction";
  payload: {
    mealMenuDiningStationId: string;
    maxAmount: number | null;
  };
}

interface AddMenuDiningStationAction {
  type: "AddMenuDiningStationAction";
  payload: {
    diningStationTemplate: DiningStationTemplateFullFragment;
  };
}

interface CreateMenuDiningStationAction {
  type: "CreateMenuDiningStationAction";
  payload: { name: string };
}

interface RemoveMenuDiningStationAction {
  type: "RemoveMenuDiningStationAction";
  payload: {
    mealMenuDiningStationId: string;
  };
}

interface ReorderMenuDiningStationAction {
  type: "ReorderMenuDiningStationAction";
  payload: {
    mealMenuDiningStations: readonly MealMenuDiningStationState[];
  };
}

interface MenuAllItemsAvailableForOrderAction {
  type: "MenuAllItemsAvailableForOrderAction";
  payload: {
    availableForOrder: boolean;
  };
}

interface MenuAllItemsAllowSpecialRequestsAction {
  type: "MenuAllItemsAllowSpecialRequestsAction";
  payload: {
    allowSpecialRequests: boolean;
  };
}

interface MenuAllItemsAllowOrderLimitAction {
  type: "MenuAllItemsAllowOrderLimitAction";
  payload: {
    userOrderLimit: number | null;
  };
}

interface MenuChangeSelectedRestaurantMenuLinkId {
  type: "MenuChangeSelectedRestaurantMenuLinkId";
  payload: {
    selectedRestaurantMenuLinkId: string | null;
  };
}

interface MenuAddRestaurantMenuLinkAction {
  type: "MenuAddRestaurantMenuLinkAction";
  payload: {
    restaurant: RestaurantPreviewFragment;
    restaurantMenu: RestaurantMenuFormFragment;
    deliveryLocation: DeliveryLocationMenuLinkFormFragment | null;
    restaurantLocation: RestaurantLocationPreviewFragment | null;
  };
}

interface MenuRemoveRestaurantMenuLink {
  type: "MenuRemoveRestaurantMenuLink";
  payload: {
    restaurantMenuLinkId: string;
  };
}

interface MenuOverrideIdentity {
  type: "MenuOverrideIdentity";
  payload: {};
}

interface MenuChangeThemeAction {
  type: "MenuChangeThemeAction";
  payload: {
    theme: string;
  };
}

interface MenuChangeEnableDiningOptionAction {
  type: "MenuChangeEnableDiningOptionAction";
  payload: {
    enableDiningOption: boolean;
  };
}

export type ShareMealMenuAction = MenuChangeTeamsAction | MenuChangeAdvancedSelectionAction | MenuClearAdvancedSelectionAction;

export type MenuDialogAction =
  | MenuChangeNameAction
  | MenuChangeMealTypeAction
  | MenuChangeStartDatesAction
  | MenuChangeStartDateAction
  | MenuChangeStartTimeAction
  | MenuChangeDurationInMinutesAction
  | MenuChangeTimezoneAction
  | MenuChangeLastOrderDaysBeforeAction
  | MenuChangeLastOrderTimeAction
  | MenuChangePrepTimeInMinutesAction
  | MenuChangeNotificationSentBeforeOrderDueInMinutesAction
  | MenuChangeOrderRateLimitAction
  | MenuChangeHubCheckInEnabledAction
  | ShareMealMenuAction
  | MenuDiningStationChangeNameAction
  | MenuDiningStationChangeMaxAmountAction
  | AddMenuDiningStationAction
  | CreateMenuDiningStationAction
  | RemoveMenuDiningStationAction
  | ReorderMenuDiningStationAction
  | MenuAllItemsAvailableForOrderAction
  | MenuAllItemsAllowSpecialRequestsAction
  | MenuAllItemsAllowOrderLimitAction
  | MenuChangeSelectedRestaurantMenuLinkId
  | MenuAddRestaurantMenuLinkAction
  | MenuRemoveRestaurantMenuLink
  | MenuOverrideIdentity
  | MenuChangeIsOrderAndLogRestrictedAction
  | (RestaurantMenuLinkAction & {
      payload: {
        restaurantMenuLinkId: string;
      };
    })
  | (MenuItemAppearanceGroupAction & {
      payload: {
        mealMenuDiningStationId: string;
      };
    })
  | MenuChangeThemeAction
  | MenuChangeEnableDiningOptionAction;

export const menuReducer = (state: MenuDialogState, action: MenuDialogAction): MenuDialogState => {
  switch (action.type) {
    case "AddMenuDiningStationAction":
      const existingMenuItemIds = state.mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances.map(mi => mi.menuItem.id));
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: [
          ...state.mealMenuDiningStations,
          {
            id: newId(),
            name: action.payload.diningStationTemplate.name,
            diningStationTemplate: {
              id: action.payload.diningStationTemplate.id,
            },
            position: getNextPosition(state.mealMenuDiningStations),
            maxAmount: action.payload.diningStationTemplate.defaultMaxAmount,
            menuItemAppearances: action.payload.diningStationTemplate.menuItemAppearances.flatMap(mia =>
              !existingMenuItemIds.includes(mia.menuItem.id)
                ? {
                    ...mia,
                    type: "Add",
                    partial: true,
                  }
                : []
            ),
          },
        ],
      };
    case "CreateMenuDiningStationAction":
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: [
          ...state.mealMenuDiningStations,
          {
            id: newId(),
            name: action.payload.name,
            position: getNextPosition(state.mealMenuDiningStations),
            maxAmount: null,
            menuItemAppearances: [],
            diningStationTemplate: null,
          },
        ],
      };
    case "RemoveMenuDiningStationAction":
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: state.mealMenuDiningStations.filter(mmds => mmds.id !== action.payload.mealMenuDiningStationId),
      };
    case "ReorderMenuDiningStationAction":
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: action.payload.mealMenuDiningStations,
      };
    case "MenuChangeNameAction":
      return {
        ...state,
        edited: true,
        name: action.payload.name,
      };
    case "MenuChangeMealTypeAction":
      return {
        ...state,
        edited: true,
        type: action.payload.type,
      };
    case "MenuChangeNotificationSentBeforeOrderDueInMinutesAction":
      return {
        ...state,
        edited: true,
        notificationSentBeforeOrderDueInMinutes: action.payload.notificationSentBeforeOrderDueInMinutes,
      };
    case "MenuChangePrepTimeInMinutesAction":
      return {
        ...state,
        edited: true,
        prepTimeInMinutes: action.payload.prepTimeInMinutes,
      };
    case "MenuChangeStartDateAction":
      if (state.__typename === "StandaloneMealMenu") {
        return { ...state, edited: true, startDate: action.payload.startDate };
      } else {
        return state;
      }
    case "MenuChangeStartDatesAction":
      if (state.__typename === "New") {
        return {
          ...state,
          edited: true,
          startDates: action.payload.startDates,
        };
      } else {
        return state;
      }
    case "MenuChangeStartTimeAction":
      return resetLastOrderDue({
        ...state,
        edited: true,
        startTime: action.payload.startTime,
      });
    case "MenuChangeTeamsAction":
      if (state.shareState.__typename === "Tags") {
        console.error("You can't assign teams when advancedSelectionState has value");
        return state;
      }
      // No way to update athlete count here but it will update on save
      return { ...state, edited: true, shareState: menuSharingReducer(state.shareState, action), hasAdvancedSelection: true };
    case "MenuClearAdvancedSelectionAction":
      return {
        ...state,
        edited: true,
        shareState: menuSharingReducer(state.shareState, action),
        athleteCount: 0,
      };
    case "MenuChangeAdvancedSelectionAction":
      // if advanced selection is cleared/empty, reset the view to team selection
      if (isAdvancedSelectionEmpty(action.payload.advancedSelectionState)) {
        return {
          ...state,
          edited: true,
          shareState: getEmptyShareState(),
          athleteCount: 0,
        };
      }
      return {
        ...state,
        edited: true,
        shareState: menuSharingReducer(state.shareState, action),
      };
    case "MenuChangeTimezoneAction":
      return { ...state, edited: true, timezone: action.payload.timezone };
    case "MenuChangeLastOrderTimeAction":
      return {
        ...state,
        edited: true,
        lastOrderTime: action.payload.lastOrderTime,
      };
    case "MenuChangeLastOrderDaysBeforeAction":
      return {
        ...state,
        edited: true,
        lastOrderDaysBefore: action.payload.lastOrderDaysBefore,
      };
    case "MenuChangeDurationInMinutesAction":
      return resetLastOrderDue({
        ...state,
        edited: true,
        durationInMinutes: action.payload.durationInMinutes,
      });
    case "MenuAllItemsAvailableForOrderAction":
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: state.mealMenuDiningStations.map(mmds => ({
          ...mmds,
          menuItemAppearances: menuItemAppearanceGroupReducer(mmds.menuItemAppearances, {
            type: "AllMenuItemsAvailableForOrderAction",
            payload: {
              availableForOrder: action.payload.availableForOrder,
            },
          }),
        })),
      };
    case "MenuAllItemsAllowSpecialRequestsAction":
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: state.mealMenuDiningStations.map(mmds => ({
          ...mmds,
          menuItemAppearances: menuItemAppearanceGroupReducer(mmds.menuItemAppearances, {
            type: "AllMenuItemsAllowSpecialRequestsAction",
            payload: {
              allowSpecialRequests: action.payload.allowSpecialRequests,
            },
          }),
        })),
      };
    case "MenuAllItemsAllowOrderLimitAction":
      return {
        ...state,
        edited: true,
        userOrderLimit: {
          limit: action.payload.userOrderLimit,
        },
      };
    case "MenuChangeOrderRateLimitAction":
      return {
        ...state,
        edited: true,
        orderRateLimit: action.payload.orderRateLimit,
      };
    case "MenuChangeHubCheckInEnabledAction":
      return {
        ...state,
        edited: true,
        isHubCheckInEnabled: action.payload.isHubCheckInEnabled,
      };
    case "MenuChangeIsOrderAndLogRestrictedAction":
      return {
        ...state,
        edited: true,
        isOrderAndLogRestricted: action.payload.isOrderAndLogRestricted,
      };
    case "MenuChangeSelectedRestaurantMenuLinkId":
      return {
        ...state,
        selectedRestaurantMenuLinkId: action.payload.selectedRestaurantMenuLinkId,
      };
    case "MenuAddRestaurantMenuLinkAction":
      const newLink = getAddRestaurantMenuLinkState(
        action.payload.restaurant,
        action.payload.restaurantMenu,
        action.payload.deliveryLocation,
        action.payload.restaurantLocation
      );
      return {
        ...state,
        edited: true,
        selectedRestaurantMenuLinkId: newLink.id,
        restaurantMenuLinks: [...state.restaurantMenuLinks, newLink],
      };
    case "MenuRemoveRestaurantMenuLink":
      return {
        ...state,
        edited: true,
        selectedRestaurantMenuLinkId: getInitialSelectedRestaurantMenuLinkId(state.restaurantMenuLinks, state.mealMenuDiningStations),
        restaurantMenuLinks: state.restaurantMenuLinks.filter(l => l.id !== action.payload.restaurantMenuLinkId),
      };
    case "MenuOverrideIdentity":
      if (!state.identity) {
        return state;
      }
      return {
        ...state,
        identity: {
          ...state.identity,
          isOverridden: true,
        },
      };
    case "MenuChangeThemeAction":
      return {
        ...state,
        edited: true,
        theme: action.payload.theme,
      };
    case "MenuChangeEnableDiningOptionAction":
      return {
        ...state,
        edited: true,
        isDiningOptionEnabled: action.payload.enableDiningOption,
      };
    case "RestaurantMenuLink_AllItemsAllowSpecialRequestsAction":
    case "RestaurantMenuLink_EditMenuItemAppearanceAction":
    case "RestaurantMenuLink_EditSectionMaxAmountAction":
    case "RestaurantMenuLink_ToggleSectionIncludedAction":
    case "RestaurantMenuLink_AddPlate":
    case "RestaurantMenuLink_EditPlateAmount":
    case "RestaurantMenuLink_EditPlateItems":
    case "RestaurantMenuLink_ToggleAllowPlateOrders":
    case "RestaurantMenuLink_ToggleAllowCustomOrders":
    case "RestaurantMenuLink_RemovePlate":
    case "RestaurantMenuLink_SetDefaultPlate":
      return {
        ...state,
        edited: true,
        restaurantMenuLinks: state.restaurantMenuLinks.map(link => {
          if (link.id !== action.payload.restaurantMenuLinkId) {
            return link;
          }
          return restaurantMenuLinkReducer(link, action);
        }),
      };
    default:
      return {
        ...state,
        edited: true,
        mealMenuDiningStations: state.mealMenuDiningStations.map(mmds => {
          if (mmds.id !== action.payload.mealMenuDiningStationId) {
            return mmds;
          }
          if (action.type === "MenuDiningStationChangeNameAction") {
            return {
              ...mmds,
              name: action.payload.name,
            };
          } else if (action.type === "MenuDiningStationChangeMaxAmountAction") {
            return {
              ...mmds,
              maxAmount: action.payload.maxAmount,
            };
          } else {
            return {
              ...mmds,
              menuItemAppearances: menuItemAppearanceGroupReducer(mmds.menuItemAppearances, action),
            };
          }
        }),
      };
  }
};

export const menuSharingReducer = (state: ShareMealMenuState, action: ShareMealMenuAction): ShareMealMenuState => {
  switch (action.type) {
    case "MenuChangeTeamsAction":
      if (state.__typename === "Tags") {
        console.error("You can't assign teams when advancedSelectionState has value");
        return state;
      }
      return { ...state, teams: action.payload.teams };
    case "MenuClearAdvancedSelectionAction":
      return getEmptyShareState();
    case "MenuChangeAdvancedSelectionAction":
      // if advanced selection is cleared/empty, reset the view to team selection
      if (isAdvancedSelectionEmpty(action.payload.advancedSelectionState)) {
        return getEmptyShareState();
      }
      return {
        teams: null,
        advancedSelectionState: action.payload.advancedSelectionState,
        __typename: "Tags",
      };
  }
};
