import { SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { BasicOption } from "../../components/universal/BaseAsyncAutocomplete";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import {
  BulkAssignMealPlanTemplateJobCreatedByFragment,
  BulkAssignMealPlanTemplateJobCreatedByCursorConnectionQueryVariables,
  useBulkAssignMealPlanTemplateJobCreatedByCursorConnectionQuery,
} from "../../types";

interface CreatedByMultiselectProps {
  selectedUsers: BulkAssignMealPlanTemplateJobCreatedByFragment[];
  searchText: string;
  onChangeSelectedUsers: (users: BulkAssignMealPlanTemplateJobCreatedByFragment[]) => void;
  sx?: SxProps;
}

export const CreatedByMultiselect = ({ onChangeSelectedUsers, selectedUsers, searchText, sx }: CreatedByMultiselectProps) => {
  const getQueryVariables = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
        searchText,
      },
    }),
    [searchText]
  );

  const transformAndFilterOptions = useCallback(
    (edges: BulkAssignMealPlanTemplateJobCreatedByFragment[] | undefined): BulkAssignMealPlanTemplateJobCreatedByFragment[] => {
      const selectedUserIds = selectedUsers.map(u => u.id);
      return edges?.filter(u => !selectedUserIds.includes(u.id)) || [];
    },
    [selectedUsers]
  );

  return (
    <InfiniteScrollAutocomplete<
      "bulkAssignMealPlanTemplateJobCreatedByCursorConnection",
      BulkAssignMealPlanTemplateJobCreatedByFragment,
      BulkAssignMealPlanTemplateJobCreatedByCursorConnectionQueryVariables,
      BulkAssignMealPlanTemplateJobCreatedByFragment,
      true,
      undefined
    >
      sx={sx}
      multiple
      getOptionSelected={usersAreEqual}
      disableCloseOnSelect
      blurOnSelect={false}
      showStartAdornment={false}
      disableRootPadding
      edgesAreEqual={usersAreEqual}
      queryKey="bulkAssignMealPlanTemplateJobCreatedByCursorConnection"
      useCursorConnectionQuery={useBulkAssignMealPlanTemplateJobCreatedByCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariables}
      transformAndFilterOptions={transformAndFilterOptions}
      renderOption={user => <BasicOption element={getUserLabel(user)} />}
      handleChange={(_, users) => onChangeSelectedUsers(users)}
      getOptionLabel={getUserLabel}
      getCustomOptionKey={(u: BulkAssignMealPlanTemplateJobCreatedByFragment) => u.id}
      noOptionsText="No Users Found"
      inputPlaceholder="Assigned By"
      value={selectedUsers}
      size="medium"
    />
  );
};

const getUserLabel = (user: BulkAssignMealPlanTemplateJobCreatedByFragment): string => {
  return `${user.firstName} ${user.lastName}`;
};

const usersAreEqual = (user1: BulkAssignMealPlanTemplateJobCreatedByFragment, user2: BulkAssignMealPlanTemplateJobCreatedByFragment) => {
  return user1.id === user2.id;
};
