import { Box, MenuItem, Select, Typography } from "@mui/material";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { KDSOrderDiningOption } from "apps/web/src/views/KDS/utils";
import { useState } from "react";

interface DiningOptionSelectProps {
    onChangeOption: (option: KDSOrderDiningOption) => void;
    diningOption: KDSOrderDiningOption | undefined;
    disabled?: boolean;
}

const DiningOptionSelect = ({ onChangeOption, diningOption, disabled }: DiningOptionSelectProps) => {
    const [pickerOpen, setPickerOpen] = useState(false);
    const showDiningOptionError = diningOption === KDSOrderDiningOption.None;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="subtitle1Semibold" color={"mediumEmphasisTextLight"} sx={{ mt: 1, mb: 0.5 }}>Dining Options</Typography>
            <Select
                onOpen={() => setPickerOpen(true)}
                onClose={() => setPickerOpen(false)}
                open={pickerOpen}
                sx={{ width: '100%' }}
                error={showDiningOptionError}
                disabled={disabled}
                value={diningOption}
                onChange={e => {
                    trackEvent(`Nutrition | Kitchen | Kiosk | Meal Order | ${e.target.value === KDSOrderDiningOption.DineIn ? "DineIn" : "ToGo"}`, {});
                    onChangeOption(e.target.value === KDSOrderDiningOption.DineIn ? KDSOrderDiningOption.DineIn : KDSOrderDiningOption.ToGo);
                }}
            >
                <MenuItem value={KDSOrderDiningOption.DineIn}>{KDSOrderDiningOption.DineIn}</MenuItem>
                <MenuItem value={KDSOrderDiningOption.ToGo}>{KDSOrderDiningOption.ToGo}</MenuItem>
            </Select>
        </Box>
    );
};

export default DiningOptionSelect;
