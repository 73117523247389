import { Box, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { BirthDateSchema } from "@notemeal/validators";
import { athleteAgeToApproxBirthDate, maybeAthleteBirthDateToAge } from "@notemeal/shared-utils-macro-protocol";

export const AthleteBirthDateSchema = z.object({
  birthDate: BirthDateSchema,
}).required();
export type AthleteBirthDateType = z.infer<typeof AthleteBirthDateSchema>;
export const athleteBirthDateFormDefaultValues: Partial<AthleteBirthDateType> = {
  birthDate: undefined,
};

interface BulkAssignMealPlanTemplateAthleteEditFormProps {
  form: UseFormReturn<AthleteBirthDateType>;
}

export const BulkAssignMealPlanTemplateAthleteEditForm = ({ form }: BulkAssignMealPlanTemplateAthleteEditFormProps) => {

  const {
    control,
    formState: { errors },
    watch,
  } = form;

  const [watchedBirthDate] = watch(["birthDate"]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="body1Medium">Basic Information</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Controller
          name={"birthDate"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <DesktopDatePicker
              sx={{ mt: 2, maxWidth: 275 }}
              {...field}
              label="Birth Date"
              slotProps={{
                textField: {
                  error: Boolean(errors.birthDate),
                  helperText: errors.birthDate?.message ?? " ",
                },
              }}
            />
          )}
        />
        <Controller
          name={"birthDate"}
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ mt: 2, maxWidth: 75 }}
              type="number"
              label="Age"
              //empty helper text gives us consistent spacing
              helperText={" "}
              disabled={!!watchedBirthDate}
              onBlur={e => !!e.target.value && field.onChange(athleteAgeToApproxBirthDate(parseInt(e.target.value)))}
              value={field.value && field.value.getDate() ? maybeAthleteBirthDateToAge(field.value.toISOString()) : undefined}
            />
          )}
        />
      </Box>
    </Box>
  );
};
