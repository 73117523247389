import { useEffect, useState } from "react";
import UpdateIcon from "@mui/icons-material/Update";
import { TWChip, TWChipColor } from "../TWChip/TWChip";
import { formatDistance } from "date-fns";
import { useDateFormatting } from "@notemeal/shared-ui";

type TWLastUpdatedBadgeProps = {
  lastUpdatedTimestamp: number;
};

export const TWLastUpdatedBadge = ({ lastUpdatedTimestamp }: TWLastUpdatedBadgeProps) => {
  const { dateFnsLocale } = useDateFormatting();
  const [baseTime, setBaseTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setBaseTime(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdatedTimestamp]);

  const timeAgoLabel = formatDistance(lastUpdatedTimestamp, lastUpdatedTimestamp - baseTime > 0 ? lastUpdatedTimestamp : baseTime, {
    includeSeconds: true,
    addSuffix: true,
    locale: dateFnsLocale,
  });

  return <TWChip
    label={`Last updated ${timeAgoLabel}`}
    chipColor={TWChipColor.blue}
    icon={<UpdateIcon />}
    smLabel={true} />;
};
