import { MenuItemAction, MenuItemState, menuItemReducer } from "../../../../components/MenuItem/reducer";
import { MenuItemFormFragment } from "../../../../types";

export interface EditMenuItemState extends MenuItemState {
  pendingRevisionAction: MenuItemAction | null;
  isRevision: boolean;
}

interface MenuItemMakeRevision {
  type: "MenuItemMakeRevision";
}

interface MenuItemCancelRevision {
  type: "MenuItemCancelRevision";
}

export type EditMenuItemAction = MenuItemAction | MenuItemMakeRevision | MenuItemCancelRevision;

export const editMenuItemReducer = (state: EditMenuItemState, action: EditMenuItemAction): EditMenuItemState => {
  switch (action.type) {
    case "MenuItemCancelRevision":
      return {
        ...state,
        pendingRevisionAction: null,
      };
    case "MenuItemMakeRevision":
      return {
        ...state,
        ...(state.pendingRevisionAction ? menuItemReducer(state, state.pendingRevisionAction) : {}),
        isRevision: true,
        pendingRevisionAction: null,
      };
    case "MenuItemChangeAvailableForOrderAction":
    case "MenuItemChangeAllowSpecialRequestsAction":
    case "MenuItemChangeMaxAmountAction":
    case "MenuItemChangeNameAction":
    case "MenuItemChangeServingNameAction":
    case "MenuItemChangeDescriptionAction":
    case "MenuItemChangeImageAction":
    case "MenuItemChangeSuggestionCategoryAction":
      return {
        ...state,
        ...menuItemReducer(state, action),
      };
    default:
      if (state.isRevision) {
        return {
          ...state,
          ...menuItemReducer(state, action),
        };
      } else {
        return {
          ...state,
          pendingRevisionAction: action,
        };
      }
  }
};

export const toEditMenuItemState = ({
  id,
  __typename,
  name,
  servingName,
  description,
  choices,
  servingAmounts,
  isOneOff,
  imageUrl,
  defaultMaxAmount,
  defaultAvailableForOrder,
  defaultAllowSpecialRequests,
  suggestionCategory,
  score,
  foodCategory,
  history,
}: MenuItemFormFragment): EditMenuItemState => ({
  menuItem: {
    id,
    __typename,
    name,
    servingName,
    description,
    choices,
    servingAmounts,
    isOneOff,
    imageUrl,
    suggestionCategory,
    defaultAvailableForOrder,
    defaultAllowSpecialRequests,
    defaultMaxAmount,
    isDeleted: false,
    score,
    foodCategory,
    history,
  },
  maxAmount: defaultMaxAmount,
  availableForOrder: defaultAvailableForOrder,
  allowSpecialRequests: defaultAllowSpecialRequests,
  edited: false,
  pendingRevisionAction: null,
  isRevision: false,
});
