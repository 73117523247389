import React, { useCallback } from "react";
import { List, ListItem, SxProps, Typography } from "@mui/material";
import { AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment } from "apps/web/src/types";
import { BulkAssignMealPlanTemplateStatusModalAthleteListItem } from "./BulkAssignMealPlanTemplateStatusModalAthleteListItem";
import { UseInfiniteCursorConnectionScrollResults } from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import InfiniteScrollContainer from "../../universal/InfiniteScroll/InfiniteScrollContainer";

const listItemStyle = { width: `calc(100% - ${1})` };

interface BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScrollProps {
  sx?: SxProps;
  searchResults: UseInfiniteCursorConnectionScrollResults<
    "athletesByBulkAssignMealPlanTemplateJobCursorConnection",
    AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment
  >;
  onClickItem: (athlete: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment) => void;
}

export const BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScroll = ({
  onClickItem,
  sx,
  searchResults,
}: BulkAssignMealPlanTemplateStatusModalAthleteInfiniteScrollProps) => {
  const renderItem = useCallback(
    (
      athlete: AthletesByBulkAssignMealPlanTemplateJobForStatusModalFragment,
      forwardRef?: React.MutableRefObject<HTMLDivElement | null>
    ) => {
      return (
        <div ref={forwardRef} key={athlete.id}>
          <BulkAssignMealPlanTemplateStatusModalAthleteListItem
            athlete={athlete}
            onClick={onClickItem}
          />
        </div>
      );
    },
    [onClickItem]
  );

  return (
    <List disablePadding sx={sx}>
      <InfiniteScrollContainer
        noItemsComponent={
          <ListItem sx={listItemStyle}>
            <Typography>No Athletes Found</Typography>
          </ListItem>
        }
        renderItem={renderItem}
        infiniteScrollResults={searchResults}
        loadingOffset={10}
      />
    </List>
  );
};
