import {
  addDays,
  addMinutes,
  subDays,
  differenceInCalendarDays,
  roundToNearestMinutes,
  parse,
  differenceInMinutes,
  subMinutes,
  startOfWeek,
} from "date-fns";
import moment from "moment-timezone";

import { dateToIsoInTz, serializeTime, parseTime, jsDateToDateAndTimeInTz, serializeDate } from "@notemeal/utils-date-time";

import { getMenuOrderItemCounts } from "../../components/MenuItemAppearance/utils";
import { getMenuItemAppearanceGroupState } from "../../components/MenuItemAppearance/reducer";
import {
  MealMenuDiningStationFormFragment,
  MealMenuCalendarFragment,
  CurrentMealMenuFragment,
  MenuOrderCountsFragment,
  TeamMealMenuPreviewFragment,
  RestaurantMenuLinkFormFragment,
  MealMenuRmlOrderPlacedOrderFragment,
  MealMenuOrdersPlacedOrderFragment,
  MealMenuRmlPlateWithOrdersFragment,
  AdvancedSelectionFragment,
} from "../../types";
import { MealMenuInstance, MenuDialogState, NewMenuDialogState, ShareMealMenuState, StandaloneMenuDialogState, TimingState } from "./types";
import { defaultTimingState } from "./BulkEditDialog/timingReducer";
import { getMostCommonValueInList } from "../../utils/getMostCommonValueInList";
import { getEditRestaurantMenuLinkState, getInitialSelectedRestaurantMenuLinkId } from "./RestaurantMenu/utils";
import { MenuType } from "./SelectComponents/MenuTypeSelect";
import {
  AdvancedSelectionState,
  buildInitialCreateAdvancedSelectionState,
  buildInitialEditAdvancedSelectionState,
} from "../Tags/reducers/advancedSelectionReducers";

export const getMealMenuInstancesOnDate = (
  mealMenuInstances: readonly MealMenuInstance[],
  date: string,
  clientTimezone: string
): readonly MealMenuInstance[] => {
  return mealMenuInstances.filter(mm => {
    const dateStart = new Date(dateToIsoInTz(date, clientTimezone));
    const dateEnd = addDays(dateStart, 1);
    return mm.start >= dateStart && mm.start <= dateEnd;
  });
};

export const getMealMenuInstances = (
  mealMenus: readonly MealMenuCalendarFragment[],
  clientTimezone: string
): readonly MealMenuInstance[] => {
  return mealMenus.map(mm => ({
    __typename: "StandaloneMealMenu",
    id: mm.id,
    edited: false,
    name: mm.name,
    type: mm.type,
    start: new Date(mm.start),
    timezone: mm.timezone || clientTimezone,
    durationInMinutes: mm.durationInMinutes,
    teams: mm.hasAdvancedSelection ? null : mm.teams,
    lastOrderTimeBeforeEndInMinutes: mm.lastOrderTimeBeforeEndInMinutes,
    notificationSentBeforeOrderDueInMinutes: mm.notificationSentBeforeOrderDueInMinutes,
    prepTimeInMinutes: mm.prepTimeInMinutes,
    mealMenuDiningStations: mm.mealMenuDiningStations,
    restaurantMenuLinks: mm.restaurantMenuLinks,
    orderRateLimit: mm.orderRateLimit,
    identity: mm.identity,
    hasAdvancedSelection: mm.hasAdvancedSelection,
    athleteCount: mm.athleteCount,
    userOrderLimit: {
      limit: mm.userOrderLimit,
    },
    isHubCheckInEnabled: mm.isHubCheckInEnabled,
    isOrderAndLogRestricted: mm.isOrderAndLogRestricted,
    ...getOrderCounts(mm),
    theme: mm.theme ?? "",
    isDiningOptionEnabled: mm.isDiningOptionEnabled || false,
  }));
};

export type MealMenuForOrderCount = {
  allOrders: readonly MealMenuOrdersPlacedOrderFragment[];
  restaurantMenuLinks: readonly {
    orders: readonly MealMenuRmlOrderPlacedOrderFragment[];
    plates: readonly MealMenuRmlPlateWithOrdersFragment[];
  }[];
};

// TODO: Multiple Orders This function makes assumptions based on one order per user. Is this athletes who have ordered or number of distinct orders FE
const getOrderCounts = (mm: MealMenuForOrderCount) => {
  const allOrders = getAllOrdersFromMealMenu(mm);
  const athleteOrders = allOrders.filter(o => !!o.athlete);
  const athleteOrderCount = new Set(athleteOrders.map(ao => ao.user.id)).size;
  const nonAthleteOrders = allOrders.filter(o => !o.athlete);
  const nonAthleteOrderCount = new Set(nonAthleteOrders.map(nao => nao.user.id)).size;
  return {
    athleteOrderCount,
    nonAthleteOrderCount,
  };
};

interface AnyOrder {
  id: string;
  user: { id: string };
  athlete: { id: string } | null;
}

export const getAllOrdersFromMealMenu = (mm: MealMenuForOrderCount): AnyOrder[] => {
  const orders = mm.allOrders.filter(mo => mo.items.some(moi => moi.forOrder));
  const rmlOrders = mm.restaurantMenuLinks.flatMap(rml => rml.orders.filter(o => o.items.length > 0));
  const rmlPlateOrders = mm.restaurantMenuLinks.flatMap(rml => rml.plates.flatMap(p => p.orders));
  return [...orders, ...rmlOrders, ...rmlPlateOrders];
};

const DEFAULT_PREP_TIME_IN_MINUTES = 15;
const DEFAULT_DURATION_IN_MINUTES = 60;

const getDateAndRoundedTime = (inputDate: Date, clientTimezone: string) => {
  const { date, time } = jsDateToDateAndTimeInTz(inputDate, clientTimezone);
  const roundedTime = serializeTime(roundToNearestMinutes(parseTime(time), { nearestTo: 15 }));
  return { date, roundedTime };
};

export const getEmptyShareState = (): ShareMealMenuState => ({
  __typename: "Teams",
  teams: [],
  advancedSelectionState: null,
});

export const getNewMenuDialogState = (clientTimezone: string, start?: Date): NewMenuDialogState => {
  const initMenuStart = start || new Date();
  const { date: startDate, roundedTime: startTime } = getDateAndRoundedTime(initMenuStart, clientTimezone);
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { date: _date, roundedTime: endTime } = getDateAndRoundedTime(
    addMinutes(initMenuStart, DEFAULT_DURATION_IN_MINUTES),
    clientTimezone
  );
  return {
    __typename: "New",
    id: null,
    edited: false,
    name: "",
    type: "breakfast",
    startDates: [startDate],
    startTime,
    timezone: clientTimezone,
    durationInMinutes: DEFAULT_DURATION_IN_MINUTES,
    mealMenuDiningStations: [],
    restaurantMenuLinks: [],
    selectedRestaurantMenuLinkId: null,
    lastOrderDaysBefore: 0,
    lastOrderTime: endTime,
    orderRateLimit: null,
    identity: null,
    prepTimeInMinutes: DEFAULT_PREP_TIME_IN_MINUTES,
    notificationSentBeforeOrderDueInMinutes: 15,
    shareState: getEmptyShareState(),
    userOrderLimit: null,
    athleteCount: 0,
    hasAdvancedSelection: false,
    isHubCheckInEnabled: false,
    isOrderAndLogRestricted: false,
    theme: "",
    isDiningOptionEnabled: false,
  };
};

export const getTimingStateFromMealMenuInstance = (mealMenu: MealMenuInstance): TimingState => {
  const { time: startTime } = jsDateToDateAndTimeInTz(mealMenu.start, mealMenu.timezone);

  return {
    startTime,
    durationInMinutes: mealMenu.durationInMinutes,
    timezone: mealMenu.timezone,
    prepTimeInMinutes: mealMenu.prepTimeInMinutes,
    ...getLastOrderDateTime({
      start: mealMenu.start,
      durationInMinutes: mealMenu.durationInMinutes,
      timezone: mealMenu.timezone,
      lastOrderTimeBeforeEndInMinutes: mealMenu.lastOrderTimeBeforeEndInMinutes,
    }),
  };
};

export const getMenuDialogStateForEdit = (
  mealMenu: MealMenuInstance,
  menuOrders: readonly MenuOrderCountsFragment[],
  mealMenuDiningStations: readonly MealMenuDiningStationFormFragment[],
  restaurantMenuLinks: readonly RestaurantMenuLinkFormFragment[],
  advancedSelection: AdvancedSelectionFragment | null
): StandaloneMenuDialogState => {
  const { date: startDate } = jsDateToDateAndTimeInTz(mealMenu.start, mealMenu.timezone);
  const shareState: ShareMealMenuState = advancedSelection
    ? {
        __typename: "Tags",
        advancedSelectionState: buildInitialEditAdvancedSelectionState(advancedSelection),
        teams: null,
      }
    : mealMenu.teams
    ? {
        __typename: "Teams",
        teams: mealMenu.teams,
        advancedSelectionState: null,
      }
    : getEmptyShareState();
  return {
    ...mealMenu,
    startDate,
    orderItemCounts: getMenuOrderItemCounts(menuOrders),
    ...getLastOrderDateTime({
      start: mealMenu.start,
      durationInMinutes: mealMenu.durationInMinutes,
      timezone: mealMenu.timezone,
      lastOrderTimeBeforeEndInMinutes: mealMenu.lastOrderTimeBeforeEndInMinutes,
    }),
    mealMenuDiningStations: mealMenuDiningStations.map(mmds => ({
      ...mmds,
      menuItemAppearances: getMenuItemAppearanceGroupState(mmds.menuItemAppearances, "edit"),
    })),
    restaurantMenuLinks: restaurantMenuLinks.map(getEditRestaurantMenuLinkState),
    selectedRestaurantMenuLinkId: getInitialSelectedRestaurantMenuLinkId(restaurantMenuLinks, mealMenuDiningStations),
    ...getTimingStateFromMealMenuInstance(mealMenu),
    shareState,
  };
};

export const getMenuDialogStateForCopy = (
  mealMenu: MealMenuInstance,
  mealMenuDiningStations: readonly MealMenuDiningStationFormFragment[],
  restaurantMenuLinks: readonly RestaurantMenuLinkFormFragment[],
  advancedSelection: AdvancedSelectionFragment | null
): MenuDialogState => {
  const shareState: ShareMealMenuState = advancedSelection
    ? {
        __typename: "Tags",
        advancedSelectionState: buildInitialEditAdvancedSelectionState(advancedSelection),
        teams: null,
      }
    : mealMenu.teams
    ? {
        __typename: "Teams",
        teams: mealMenu.teams,
        advancedSelectionState: null,
      }
    : getEmptyShareState();
  return {
    ...mealMenu,
    id: null,
    identity: null,
    __typename: "New",
    startDates: [],
    startTime: serializeTime(mealMenu.start),
    ...getLastOrderDateTime({
      start: mealMenu.start,
      durationInMinutes: mealMenu.durationInMinutes,
      timezone: mealMenu.timezone,
      lastOrderTimeBeforeEndInMinutes: mealMenu.lastOrderTimeBeforeEndInMinutes,
    }),
    timezone: mealMenu.timezone,
    mealMenuDiningStations: mealMenuDiningStations.map(mmds => ({
      ...mmds,
      menuItemAppearances: getMenuItemAppearanceGroupState(mmds.menuItemAppearances, "create"),
    })),
    restaurantMenuLinks: restaurantMenuLinks.map(getEditRestaurantMenuLinkState),
    selectedRestaurantMenuLinkId: getInitialSelectedRestaurantMenuLinkId(restaurantMenuLinks, mealMenuDiningStations),
    prepTimeInMinutes: mealMenu.prepTimeInMinutes,
    shareState,
    theme: mealMenu.theme,
  };
};

export const getMenuDialogStateForFinishedCopy = (
  finishedMealMenu: MealMenuInstance,
  currentMealMenu: CurrentMealMenuFragment
): MenuDialogState => {
  return getMenuDialogStateForCopy(
    finishedMealMenu,
    currentMealMenu.mealMenuDiningStations.map(({ position, id, name, diningStationTemplate, menuItemAppearances, maxAmount }) => {
      return {
        id,
        name,
        position,
        maxAmount,
        diningStationTemplate,
        menuItemAppearances: menuItemAppearances.map(({ id, maxAmount, position, menuItem, availableForOrder, allowSpecialRequests }) => {
          return {
            id,
            maxAmount,
            position,
            availableForOrder,
            allowSpecialRequests,
            menuItem: menuItem.history.current,
          };
        }),
      };
    }),
    currentMealMenu.restaurantMenuLinks,
    currentMealMenu.advancedSelection
  );
};

interface getLastOrderTimeBeforeEndInMinutesArgs {
  startTime: string;
  durationInMinutes: number;
  lastOrderDaysBefore: number;
  lastOrderTime: string;
}

export const getLastOrderTimeBeforeEndInMinutes = ({
  startTime,
  durationInMinutes,
  lastOrderTime,
  lastOrderDaysBefore,
}: getLastOrderTimeBeforeEndInMinutesArgs): number => {
  // We received bugs from when today's date was near Daylight Savings Time.  This is a workaround.
  //
  // Pick a date not near Daylight Savings Time so that the math works.  Newer versions of date-fns
  // give better ways to eliminate DST when not wanted.
  const start = parse(startTime, "HH:mm:00", new Date("2024-08-15"));
  const end = addMinutes(start, durationInMinutes);
  const lastOrder = subDays(parse(lastOrderTime, "HH:mm:00", end), lastOrderDaysBefore);
  return differenceInMinutes(end, lastOrder);
};

interface getLastOrderDateTimeArgs {
  start: Date;
  timezone: string;
  durationInMinutes: number;
  lastOrderTimeBeforeEndInMinutes: number;
}

const getLastOrderDateTime = ({ start, durationInMinutes, lastOrderTimeBeforeEndInMinutes, timezone }: getLastOrderDateTimeArgs) => {
  const adjustedStart = moment.tz(start, timezone).tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true).toDate();
  const adjustedEnd = addMinutes(adjustedStart, durationInMinutes);
  const lastOrder = subMinutes(adjustedEnd, lastOrderTimeBeforeEndInMinutes);

  return {
    lastOrderDaysBefore: differenceInCalendarDays(adjustedEnd, lastOrder),
    lastOrderTime: serializeTime(lastOrder),
  };
};

// TODO: Tags V1 will need to make a filter that works with advanced selection
export const teamFilter = (mealMenuInstance: MealMenuInstance, teamIds: string[]): boolean => {
  if (mealMenuInstance.hasAdvancedSelection || mealMenuInstance.teams === null) {
    return true;
  } else if (teamIds.length === 0) {
    return true;
  } else {
    return mealMenuInstance.teams.some(t => teamIds.includes(t.id));
  }
};

export const menuTypeFilter = (mealMenuInstance: MealMenuInstance, menuType: MenuType) => {
  switch (menuType) {
    case "Catering":
      return mealMenuInstance.restaurantMenuLinks.length > 0;
    case "In-House":
      return mealMenuInstance.mealMenuDiningStations.length > 0;
    case "All":
      return true;
  }
};

export const getFilteredMenus = (mealMenuInstances: readonly MealMenuInstance[], teamIds: string[], menuType: MenuType) => {
  return mealMenuInstances.filter(mmi => menuTypeFilter(mmi, menuType) && teamFilter(mmi, teamIds));
};

type InitialSharingStatePayload = [TeamMealMenuPreviewFragment[] | null, AdvancedSelectionState | null];

export const getInitialBulkEditSharingState = (bulkEditMealMenus: readonly MealMenuInstance[]): InitialSharingStatePayload => {
  const hasAdvancedSelectionState = bulkEditMealMenus.some(mm => mm.hasAdvancedSelection);
  if (hasAdvancedSelectionState) {
    return [null, buildInitialCreateAdvancedSelectionState()];
  }
  const initialSelectedTeams = bulkEditMealMenus.reduce((tempSet: Set<TeamMealMenuPreviewFragment>, mealMenu: MealMenuInstance) => {
    return new Set([...tempSet, ...(mealMenu.teams ?? [])]);
  }, new Set([]));

  return [[...initialSelectedTeams], null];
};

export const getInitialBulkEditNotificationValue = (bulkEditMealMenus: readonly MealMenuInstance[]) => {
  const maxNotificationOccrance = getMostCommonValueInList(bulkEditMealMenus.map(menu => menu.notificationSentBeforeOrderDueInMinutes));
  const initialNotificationValue = maxNotificationOccrance;
  return initialNotificationValue;
};

export const getInitialBulkEditTimingState = (bulkEditMealMenus: readonly MealMenuInstance[], clientTimezone: string) => {
  if (bulkEditMealMenus.length === 0) {
    return defaultTimingState;
  }
  const bulkEditMealMenusTimingInfo = bulkEditMealMenus.map<TimingState>((mealMenuInstance: MealMenuInstance) => {
    return getTimingStateFromMealMenuInstance(mealMenuInstance);
  });

  const timeZones = [...bulkEditMealMenusTimingInfo.map(info => info.timezone), clientTimezone];
  const tempTimezone = getMostCommonValueInList(timeZones);
  const timezone = tempTimezone === null ? clientTimezone : tempTimezone;

  const lastOrderDaysBefore = Math.min(...bulkEditMealMenusTimingInfo.map(info => info.lastOrderDaysBefore));

  const prepTimeInMinutes = Math.max(...bulkEditMealMenusTimingInfo.map(info => info.prepTimeInMinutes));

  const durationInMinutes = Math.max(...bulkEditMealMenusTimingInfo.map(info => info.durationInMinutes));

  const initialTimingState = bulkEditMealMenusTimingInfo.reduce((combinedTimingState, timingState) => {
    const currStart = parseTime(combinedTimingState.startTime);
    const incomingStart = parseTime(timingState.startTime);
    const incomingIsBefore = incomingStart < currStart;

    const startTime = incomingIsBefore ? timingState.startTime : combinedTimingState.startTime;
    return {
      startTime,
      durationInMinutes,
      timezone,
      lastOrderDaysBefore,
      lastOrderTime: startTime,
      prepTimeInMinutes,
    };
  });

  return initialTimingState;
};

export const menuHasIdentityWithoutOverride = (state: StandaloneMenuDialogState): boolean => {
  return Boolean(state.identity && !state.identity.isOverridden);
};

export const getNoItemsAvailableForOrder = (menuState: StandaloneMenuDialogState | NewMenuDialogState) => {
  const noInhouseItemsAvailable = menuState.mealMenuDiningStations
    .flatMap(mmds => mmds.menuItemAppearances)
    .every(mia => !mia.availableForOrder);

  const noCateringItemsAvailable = menuState.restaurantMenuLinks.every(rml => {
    const noCustomOrders =
      !rml.allowCustomOrders ||
      rml.restaurantMenu.sections.flatMap(section => section.menuItemAppearances.filter(item => item.included)).length === 0;
    const noPlatesForOrder = !rml.allowPlateOrders || rml.plates.length === 0;
    return noCustomOrders && noPlatesForOrder;
  });

  return noInhouseItemsAvailable && noCateringItemsAvailable;
};

type WeekRange = {
  start: string;
  end: string;
};

export const getSelectedWeek = (date: Date | null): WeekRange => {
  date = date || new Date();

  const startOfWeekDate = startOfWeek(date);
  const endOfWeekDate = addDays(startOfWeekDate, 6);

  return {
    start: serializeDate(startOfWeekDate),
    end: serializeDate(endOfWeekDate),
  };
};
